import { observable } from "mobx";
import { createContext } from "react";
import { userLogin, checkSavedToken, validateLoginOtp } from "../api";

export enum LoginStatus {
  NotYet,
  Checking,
  Waiting,
  Succeded,
  Failed,
}

class LoginDataStorage {
  @observable status = LoginStatus.NotYet;
  @observable token = "";

  async checkStoredToken() {
    this.status = LoginStatus.Checking;
    let token = localStorage.getItem("userToken");
    if (token && token !== "") {
      let response = await checkSavedToken(token);
      if (response[0] && response[1]["success"]) {
        this.status = LoginStatus.Succeded;
        this.token = token;
        return;
      } else {
        this.status = LoginStatus.NotYet;
        localStorage.removeItem("userToken");
        return;
      }
    } else {
      this.status = LoginStatus.NotYet;
      //localStorage.clear();
      return;
    }
  }

  async login(email: string, password: string) {
    if (this.status === LoginStatus.Waiting) return;
    this.status = LoginStatus.Waiting;
    let response = await userLogin(email, password);
    if (!response[0]) {
      this.token = "";
      this.status = LoginStatus.Failed;
      //localStorage.clear();
      return;
    }

    const has2fa = !response?.[1]?.["token"];
    if (has2fa) return has2fa;

    localStorage.setItem("userToken", response[1]["token"]);
    this.token = response[1]["token"];
    this.status = LoginStatus.Succeded;
    return false;
  }

  async validate(email: string, otp: string) {
    let response = await validateLoginOtp(email, otp);
    if (!response[0]) {
      this.token = "";
      this.status = LoginStatus.Failed;
      //localStorage.clear();
      return;
    }

    localStorage.setItem("userToken", response[1]["token"]);
    this.token = response[1]["token"];
    this.status = LoginStatus.Succeded;
    return
  }
}

export const LoginDataStoreContext = createContext(new LoginDataStorage());
