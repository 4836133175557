import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function ManagementPopup(props: { show: boolean, handleCancel: Function, handleConfirm: Function, title: string, formId?: string, children: React.ReactNode }) {

    const [t, i18n] = useTranslation("common");

    return (
        <Modal show={props.show} onHide={() => { props.handleCancel() }}>
            <Modal.Header>
                <Modal.Title style={{ marginLeft: "auto", marginRight: "auto" }}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "2rem" }}>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.handleCancel(); }}>
                    {t("pages.management.popup.btn-close")}
                </Button>
                {
                    props.formId ?
                        <Button variant="primary" form={props.formId} type={"submit"}>
                            {t("pages.management.popup.btn-save")}
                        </Button>
                        :
                        <Button variant="primary" onClick={async () => { await props.handleConfirm(); }}>
                            {t("pages.management.popup.btn-save")}
                        </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}