import React, { useState, SyntheticEvent, useContext } from "react";

import { DronesDataStoreContext } from "../../../stores/drones.store";
import { ModelsDataStoreContext } from "../../../stores/models.store";

import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

const DronesForm = observer(() => {
  const [t, i18n] = useTranslation("common");

  const dronesDataStore = useContext(DronesDataStoreContext);
  const modelsDataStore = useContext(ModelsDataStoreContext);

  const [sn, setSN] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [owner, setOwner] = useState("");
  const [type, setType] = useState("");

  const dicType: any = {
    [t("drone.type-friend")]: "FRIEND",
    [t("drone.type-neutral")]: "NEUTRAL",
    [t("drone.type-enemy")]: "ENEMY",
    [t("drone.type-stolen")]: "STOLEN",
    [t("drone.type-medicalized")]: "MEDICAL",
    [t("drone.type-authorized")]: "AUTHORIZED",
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!sn || !model || !owner || !type) {
      alert(t("pages.drones.form.alert-invalid-fields"));
      return;
    }

    var body: any = {};

    body.sn = sn;
    body.model = model;
    body.owner = owner;
    body.type = dicType[type];

    dronesDataStore.createDrone(body);
    dronesDataStore.getDrones();
  };

  var dronesBrand: any = [];
  var uniqueBrand: any = {};
  var dronesModel: any = [];
  for (var j = 0; j < modelsDataStore.models.length; j++) {
    let model = toJS(modelsDataStore.models[j]);
    if (!uniqueBrand[model.brand]) {
      uniqueBrand[model.brand] = [];
      dronesBrand.push(
        <option key={"model_" + j} value={model.brand}>
          {model.brand}
        </option>
      );
    }
    uniqueBrand[model.brand].push({ model: model.model, id: model._id });
    if (!brand) {
      dronesModel.push(
        <option key={"brand_" + j} value={model._id}>
          {model.model}
        </option>
      );
    }
  }
  dronesBrand.sort((a: any, b: any) => {
    if (a.props.value?.toUpperCase() > b.props.value?.toUpperCase()) {
      return 1;
    }
    if (a.props.value?.toUpperCase() < b.props.value?.toUpperCase()) {
      return -1;
    }
    return 1;
  });

  if (brand) {
    for (var i = 0; i < uniqueBrand[brand].length; i++) {
      dronesModel.push(
        <option key={"brand_" + i} value={uniqueBrand[brand][i].id}>
          {uniqueBrand[brand][i].model}
        </option>
      );
    }
  }

  dronesModel.sort((a: any, b: any) => {
    if (a.props.children?.toUpperCase() > b.props.children?.toUpperCase()) {
      return 1;
    }
    if (a.props.children?.toUpperCase() < b.props.children?.toUpperCase()) {
      return -1;
    }
    return 1;
  });

  function setModelsByBrand(brand: any) {
    setBrand(brand);
    setModel("");
  }

  return (
    <Accordion style={{ marginBottom: "30px", width: "49%" }}>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5 style={{ margin: "0" }}>{t("pages.drones.form.header")}</h5>
          <FontAwesomeIcon icon={faAngleDown} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="-1">
          <Card.Body style={{ padding: "1.25rem 1.25rem" }}>
            <Form onSubmit={handleSubmit}>
              <Row style={{ margin: 0 }}>
                <Col style={{ paddingLeft: 0 }}>
                  <Form.Group controlId="formGroupDroneSN">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {t("drone.identifier")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={sn}
                      onChange={(v) => setSN(v.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col style={{ paddingRight: 0 }}>
                  <Form.Group controlId="formGroupDroneOwner">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {t("drone.owner")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={owner}
                      onChange={(v) => setOwner(v.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ margin: 0 }}>
                <Col style={{ paddingLeft: 0 }}>
                  <Form.Group controlId="formGroupDroneBrand">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {t("drone.brand")}
                    </Form.Label>
                    <Form.Control
                      value={brand}
                      onChange={(v) => setModelsByBrand(v.target.value)}
                      as="select"
                    >
                      <option value={""}>-----------</option>
                      {dronesBrand}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col style={{ paddingRight: 0 }}>
                  <Form.Group controlId="formGroupDroneModel">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {t("drone.model")}
                    </Form.Label>
                    <Form.Control
                      value={model}
                      onChange={(v) => setModel(v.target.value)}
                      as="select"
                    >
                      <option value={""}>-----------</option>
                      {dronesModel}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ margin: 0 }}>
                <Col style={{ paddingRight: 0, paddingLeft: 0 }}>
                  <Form.Group controlId="formGroupDroneType">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {t("drone.type")}
                    </Form.Label>
                    <Form.Control
                      value={type}
                      onChange={(v) => setType(v.target.value)}
                      as="select"
                    >
                      <option value={""}>-----------</option>
                      <option>{t("drone.type-friend")}</option>
                      <option>{t("drone.type-neutral")}</option>
                      <option>{t("drone.type-enemy")}</option>
                      <option>{t("drone.type-stolen")}</option>
                      <option>{t("drone.type-medicalized")}</option>
                      <option>{t("drone.type-authorized")}</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Button block type="submit">
                {t("pages.drones.form.btn-add")}
              </Button>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
});

export default DronesForm;
