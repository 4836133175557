import React, { useState } from "react";
import { Form, ListGroupItem, Row } from "react-bootstrap";
import { addDetectorToGroup } from "../../../api";
import { IGroupDetector } from "../../../interfaces/group.interface";
import { useTranslation } from "react-i18next";

export function ManagementGroupDetectorForm(props: {
  groupId: string;
  detectors: Array<IGroupDetector>;
  parentDetectors: Array<IGroupDetector>;
  fncClosePopup: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [detectorId, setDetectorId] = useState<string>();

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (!detectorId) {
      alert(t("pages.management.detectors.popup.alert-invalid-add"));
    } else {
      if (!(await addDetectorToGroup(props.groupId, detectorId))[0]) {
        alert(t("pages.management.detectors.popup.alert-add-error"));
      } else {
        props.fncClosePopup();
      }
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <Form
        style={{ width: "100%" }}
        id="managementDetectorForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formDetectorName" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.popup.label-info")}
          </Form.Label>
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
          {props.parentDetectors && props.parentDetectors.length > 0 ? (
            props.parentDetectors
              .filter(
                (parentDetector) =>
                  parentDetector._id &&
                  !props.detectors.find(
                    (detector) => detector._id === parentDetector._id
                  )
              )
              .map((parentDetector) => {
                return (
                  <ListGroupItem
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      backgroundColor:
                        parentDetector._id == detectorId
                          ? "rgba(220,220,220,.7)"
                          : undefined,
                    }}
                    key={"detector_" + parentDetector._id}
                    onClick={() => {
                      setDetectorId(parentDetector._id);
                    }}
                  >
                    <Row
                      style={{
                        width: "100%",
                        margin: 0,
                        padding: 0,
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{parentDetector.name}</span>
                      <span>
                        {parentDetector.isMobile
                          ? t(
                              "pages.management.detectors.popup.mobile-detector"
                            )
                          : t(
                              "pages.management.detectors.popup.fixed-detector"
                            )}
                      </span>
                    </Row>
                  </ListGroupItem>
                );
              })
          ) : (
            <p>{t(
              "pages.management.detectors.popup.not-available-detectors"
            )}</p>
          )}
          </div>
        </Form.Group>
      </Form>
    </Row>
  );
}
