import React, { useState } from "react";
import { Form, ListGroupItem, Row } from "react-bootstrap";
import { addInhibitorToGroup } from "../../../api";
import { IGroupInhibitor } from "../../../interfaces/group.interface";
import { useTranslation } from "react-i18next";

export function ManagementGroupInhibitorForm(props: {
  groupId: string;
  inhibitors: Array<IGroupInhibitor>;
  parentInhibitors: Array<IGroupInhibitor>;
  fncClosePopup: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [inhibitorId, setInhibitorId] = useState<string>();

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (!inhibitorId) {
      alert(t("pages.management.inhibitors.popup.alert-invalid-add"));
    } else {
      if (!(await addInhibitorToGroup(props.groupId, inhibitorId))[0]) {
        alert(t("pages.management.inhibitors.popup.alert-add-error"));
      } else {
        props.fncClosePopup();
      }
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <Form
        style={{ width: "100%" }}
        id="managementInhibitorForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formInhibitorName" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.inhibitors.popup.label-info")}
          </Form.Label>
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
            {props.parentInhibitors && props.parentInhibitors.length > 0 ? (
              props.parentInhibitors
                .filter(
                  (parentInhibitor) =>
                    parentInhibitor._id &&
                    !props.inhibitors.find(
                      (inhibitor) => inhibitor._id === parentInhibitor._id
                    )
                )
                .map((parentInhibitor) => {
                  return (
                    <ListGroupItem
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        backgroundColor:
                          parentInhibitor._id == inhibitorId
                            ? "rgba(220,220,220,.7)"
                            : undefined,
                      }}
                      key={"inhibitor_" + parentInhibitor._id}
                      onClick={() => {
                        setInhibitorId(parentInhibitor._id);
                      }}
                    >
                      <Row
                        style={{
                          width: "100%",
                          margin: 0,
                          padding: 0,
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{parentInhibitor.name}</span>
                        <span>
                          {parentInhibitor.isMobile
                            ? t(
                                "pages.management.inhibitors.popup.mobile-inhibitor"
                              )
                            : t(
                                "pages.management.inhibitors.popup.fixed-inhibitor"
                              )}
                        </span>
                      </Row>
                    </ListGroupItem>
                  );
                })
            ) : (
              <p>
                {t(
                  "pages.management.inhibitors.popup.not-available-inhibitors"
                )}
              </p>
            )}
          </div>
        </Form.Group>
      </Form>
    </Row>
  );
}
