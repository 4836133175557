import React, { useContext, useEffect, useState } from 'react';

import { ActivePageStoreContext, PageIndex } from '../../../stores/activepage.store';
import { DronesDataStoreContext, DronesStatus } from '../../../stores/drones.store';
import { RegistersDataStoreContext } from '../../../stores/registersdata.store';
import { ModelsDataStoreContext } from '../../../stores/models.store';
import DronesForm from './dronesform.component';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { observer } from 'mobx-react-lite';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSearch, faTimes, faFilter } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { UserDataStoreContext } from '../../../stores/userdata.store';
import { useTranslation } from 'react-i18next';


const DronesColumn = observer(() => {

    const [t, i18n] = useTranslation("common");

    const drones = useContext(DronesDataStoreContext);
    const modelsDataStore = useContext(ModelsDataStoreContext);
    const registersDataStore = useContext(RegistersDataStoreContext);
    const activepageStore = useContext(ActivePageStoreContext);
    const userStore = useContext(UserDataStoreContext);

    const [page, setPage] = useState(2);

    useEffect(() => {
        cleanFilters();
        setBrand("");
        setModel("");
        modelsDataStore.getModels();
    }, [])

    async function loadDrones() {
        let query = "";
        if (snFilter) {
            query += "&sn=" + snFilter;
        }
        if (brand) {
            query += "&brand=" + brand;
        }
        if (model) {
            query += "&model=" + model;
        }
        if (typeFilter) {
            query += "&type=" + typeFilter;
        }
        drones.drones = [];
        await drones.getDrones(1, query);
        setPage(2);
    }

    async function loadMoreDrones() {
        let query = "";
        if (snFilter) {
            query += "&sn=" + snFilter;
        }
        if (brand) {
            query += "&brand=" + brand;
        }
        if (model) {
            query += "&model=" + model;
        }
        if (typeFilter) {
            query += "&type=" + typeFilter;
        }
        await drones.getDrones(page, query);
        setPage(prev => prev + 1);
    }

    const [typeFilter, setTypeFilter]: any = useState([false, false, false, false, true]);
    const [snFilter, setSNFilter] = useState("");
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");

    function cleanFilters() {
        setBrand("");
        setModel("");
        setSNFilter("");
        setTypeFilter("");
    }

    function changeDroneRelation(droneId: any, newType: String) {
        let body = { type: newType };
        drones.updateDrone(droneId, body);
        setTimeout(() => loadDrones(), 500)
    }

    var dronesBrand: any = [];
    var uniqueBrand: any = {};
    var dronesModel: any = [];
    for (var j = 0; j < modelsDataStore.models.length; j++) {
        let model = toJS(modelsDataStore.models[j]);
        if (!uniqueBrand[model.brand]) {
            uniqueBrand[model.brand] = [];
            dronesBrand.push(
                <option value={model.brand}>{model.brand}</option>
            )
        }
        uniqueBrand[model.brand].push(model.model);
        if (!brand) {
            model.model && dronesModel.push(
                <option value={model.model}>{model.model}</option>
            )
        }
    }
    dronesBrand.sort((a: any, b: any) => {
        if (a.props.value?.toUpperCase() > b.props.value?.toUpperCase()) { return 1 }
        if (a.props.value?.toUpperCase() < b.props.value?.toUpperCase()) { return -1 }
        return 1
    })

    if (brand) {
        for (var i = 0; i < uniqueBrand[brand].length; i++) {
            dronesModel.push(
                <option value={uniqueBrand[brand][i]}>{uniqueBrand[brand][i]}</option>
            )
        }
    }
    dronesModel.sort((a: any, b: any) => {
        if (a.props.value?.toUpperCase() > b.props.value?.toUpperCase()) { return 1 }
        if (a.props.value?.toUpperCase() < b.props.value?.toUpperCase()) { return -1 }
        return 1
    })

    function setModelsByBrand(brand: any) {
        setBrand(brand);
        setModel("");
    }

    function showDroneDetections(droneSN: string) {
        registersDataStore.queryParams = '';
        registersDataStore.queryParams += '?sn=' + droneSN;
        activepageStore.changeActivePage(PageIndex.Registros);
    }

    var dronesList: any = [];
    //<img src={'api/img/models/' + productId + '.png'} style={{ width: "100%" }}></img>
    for (let drone of drones.drones) {
        let modelImage = drone.model ? drone.model.image : "unknown-drone.png"
        
        dronesList.push(
            <ListGroup.Item key={"drone" + drone._id} style={{ display: "flex" }}>
                <Col xs={3} style={{ display: "flex", alignItems: "center" }}>
                    <img src={'api/img/models/' + modelImage} style={{ width: "50%" }}></img>
                </Col>
                <Col xs={9} style={{ padding: "0" }}>
                    <Table responsive style={{ textAlign: "left" }}>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: "bold", borderTop: "none", width: "20%" }}> {t("drone.brand")} </td>
                                <td style={{ borderTop: "none", width: "30%" }}> {drone.model ? drone.model.brand : t("drone.brand-unknown")} </td>
                                <td style={{ fontWeight: "bold", borderTop: "none", width: "20%" }}> {t("drone.model")} </td>
                                <td style={{ borderTop: "none", width: "30%" }}> {drone.model ? drone.model.model : t("drone.model-unknown")} </td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}> {t("drone.identifier")} </td>
                                <td> {drone.owner ? drone.owner === "FFCCSE" ? "FFCCSE" : drone.sn : drone.sn} </td>
                                <td style={{ fontWeight: "bold" }}> {t("drone.owner")} </td>
                                <td>{drone.owner ? drone.owner : t("drone.owner-unknown")}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}> {t("drone.detections")} </td>
                                <td>
                                    <div>
                                        {/*drone.detections ? drone.detections : 0*/}
                                        <OverlayTrigger
                                            key={drone._id.toString() + "DETECTIONS"}
                                            placement={"right"}
                                            overlay={
                                                <Tooltip id={`tooltip-${drone._id.toString() + "DETECTIONS"}`}>
                                                    {t("drone.detections-hover")}
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon icon={faSearch} style={{ marginLeft: "50px", cursor: "pointer" }} onClick={() => { showDroneDetections(drone.sn); }} />
                                        </OverlayTrigger>
                                    </div>
                                </td>
                                <td colSpan={2} style={{ fontWeight: "bold", width: "50%" }}>
                                    {
                                        userStore.user.role !== "USER" ?
                                            drone.owner === "FFCCSE" ?
                                                null
                                                :
                                                drone.type === "MEDICAL"
                                                    ?
                                                    <Button style={{ backgroundColor: "#3399FF" }}>{t("drone.type-medicalized")}</Button>
                                                    :
                                                    <ButtonGroup style={{}}>
                                                        <OverlayTrigger
                                                            key={drone._id.toString() + "FRIEND"}
                                                            placement={"bottom"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${drone._id.toString() + "FRIEND"}`}>
                                                                    {t("pages.drones.card.btn-friend-hover")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button onClick={() => changeDroneRelation(drone._id, "FRIEND")} className={"optionButton"} style={{ backgroundColor: drone.type === "FRIEND" ? "green" : "grey", borderColor: drone.type === "FRIEND" ? "green" : "grey" }}>{t("pages.drones.card.btn-friend")}</Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            key={drone._id.toString() + "NEUTRAL"}
                                                            placement={"bottom"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${drone._id.toString() + "NEUTRAL"}`}>
                                                                    {t("pages.drones.card.btn-neutral-hover")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button onClick={() => changeDroneRelation(drone._id, "NEUTRAL")} className={"optionButton"} style={{ backgroundColor: drone.type === "NEUTRAL" ? "orange" : "grey", borderColor: drone.type === "NEUTRAL" ? "orange" : "grey" }}>{t("pages.drones.card.btn-neutral")}</Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            key={drone._id.toString() + "ENEMY"}
                                                            placement={"bottom"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${drone._id.toString() + "ENEMY"}`}>
                                                                    {t("pages.drones.card.btn-enemy-hover")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button onClick={() => changeDroneRelation(drone._id, "ENEMY")} className={"optionButton"} style={{ backgroundColor: drone.type === "ENEMY" ? "red" : "grey", borderColor: drone.type === "ENEMY" ? "red" : "grey" }}>{t("pages.drones.card.btn-enemy")}</Button>
                                                        </OverlayTrigger>
                                                        {/* Button for NEUTRALIZED */}
    <OverlayTrigger
        key={drone._id.toString() + "NEUTRALIZED"}
        placement={"bottom"}
        overlay={
            <Tooltip id={`tooltip-${drone._id.toString() + "NEUTRALIZED"}`}>
                {t("pages.drones.card.btn-neutralized-hover")}
            </Tooltip>
        }
    >
        <Button 
            onClick={() => changeDroneRelation(drone._id, "NEUTRALIZED")} 
            className={"optionButton"} 
            style={{ 
                backgroundColor: drone.type === "NEUTRALIZED" ? "black" : "grey", 
                borderColor: drone.type === "NEUTRALIZED" ? "black" : "grey" 
            }}
        >
            {t("pages.drones.card.btn-neutralized")}
        </Button>
    </OverlayTrigger>

    {/* Button for NOTIFY */}
    <OverlayTrigger
        key={drone._id.toString() + "NOTIFY"}
        placement={"bottom"}
        overlay={
            <Tooltip id={`tooltip-${drone._id.toString() + "NOTIFY"}`}>
                {t("pages.drones.card.btn-notify-hover")}
            </Tooltip>
        }
    >
        <Button 
            onClick={() => changeDroneRelation(drone._id, "NOTIFY")} 
            className={"optionButton"} 
            style={{ 
                backgroundColor: drone.type === "NOTIFY" ? "blue" : "grey", 
                borderColor: drone.type === "NOTIFY" ? "blue" : "grey" 
            }}
        >
            {t("pages.drones.card.btn-notify")}
        </Button>
    </OverlayTrigger>

    {/* Button for AUTHORIZED */}
    <OverlayTrigger
        key={drone._id.toString() + "AUTHORIZED"}
        placement={"bottom"}
        overlay={
            <Tooltip id={`tooltip-${drone._id.toString() + "AUTHORIZED"}`}>
                {t("pages.drones.card.btn-authorized-hover")}
            </Tooltip>
        }
    >
        <Button 
            onClick={() => changeDroneRelation(drone._id, "AUTHORIZED")} 
            className={"optionButton"} 
            style={{ 
                backgroundColor: drone.type === "AUTHORIZED" ? "green" : "grey", 
                borderColor: drone.type === "AUTHORIZED" ? "green" : "grey" 
            }}
        >
            {t("pages.drones.card.btn-authorized")}
        </Button>
    </OverlayTrigger>
    {/* Button for STOLEN */}
    <OverlayTrigger
        key={drone._id.toString() + "STOLEN"}
        placement={"bottom"}
        overlay={
            <Tooltip id={`tooltip-${drone._id.toString() + "STOLEN"}`}>
                {t("pages.drones.card.btn-stolen-hover")}
            </Tooltip>
        }
    >
        <Button 
            onClick={() => changeDroneRelation(drone._id, "STOLEN")} 
            className={"optionButton"} 
            style={{ 
                backgroundColor: drone.type === "STOLEN" ? "chocolate" : "grey", 
                borderColor: drone.type === "STOLEN" ? "chocolate" : "grey" 
            }}
        >
            {t("pages.drones.card.btn-stolen")}
        </Button>
    </OverlayTrigger>
                                                    </ButtonGroup>
                                            :
                                            null
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Col>
            </ListGroup.Item>
        )
    }

    return (
        <Card style={{ width: "100%", height: "100%" }}>
            <Card.Header style={{ display: "flex", justifyContent: "center" }}>
                <h3>{t("pages.drones.header")}</h3>
            </Card.Header>
            <Card.Body style={{ textAlign: "center", overflowY: "scroll" }}>
                <Row style={{ margin: "0", display: "flex", justifyContent: "space-between" }}>
                    <DronesForm />
                    <Accordion style={{ marginBottom: "30px", width: "49%" }}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="-2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h5 style={{ margin: "0" }}>{t("pages.drones.filter.header")}</h5>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="-2">
                                <Card.Body style={{ padding: '1.25rem 1.25rem' }}>
                                    <Row style={{ margin: "0px", alignItems: "center", justifyContent: "space-between" }} onSubmit={(e: any) => { e.preventDefault(); }} >
                                        <Form style={{ width: "100%" }}>
                                            <Row>
                                                <Col style={{ margin: 0 }}>
                                                    <Form.Group controlId="formGroupSN">
                                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("drone.identifier")}</Form.Label>
                                                        <Form.Control value={snFilter} placeholder={t("pages.drones.filter.identifier-placeholder")}
                                                            aria-describedby="inputGroup-sizing-default"
                                                            onChange={(v) => setSNFilter(v.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group controlId="formGroupType">
                                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("drone.type")}</Form.Label>
                                                        <Row style={{ margin: 0, display: "flex", justifyContent: "center" }}>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type={"radio"}
                                                                label={t("drone.type-friend")}
                                                                id={"friendDrones"}
                                                                defaultChecked={typeFilter === "FRIEND" ? true : false}
                                                                checked={typeFilter === "FRIEND" ? true : false}
                                                                onChange={() => setTypeFilter("FRIEND")}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type={"radio"}
                                                                label={t("drone.type-neutral")}
                                                                id={"neutralDrones"}
                                                                defaultChecked={typeFilter === "NEUTRAL" ? true : false}
                                                                checked={typeFilter === "NEUTRAL" ? true : false}
                                                                onChange={() => setTypeFilter("NEUTRAL")}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type={"radio"}
                                                                label={t("drone.type-enemy")}
                                                                id={"enemyDrones"}
                                                                defaultChecked={typeFilter === "ENEMY" ? true : false}
                                                                checked={typeFilter === "ENEMY" ? true : false}
                                                                onChange={() => setTypeFilter("ENEMY")}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type={"radio"}
                                                                label={t("drone.type-stolen")}
                                                                id={"stolenDrones"}
                                                                defaultChecked={typeFilter === "STOLEN" ? true : false}
                                                                checked={typeFilter === "STOLEN" ? true : false}
                                                                onChange={() => setTypeFilter("STOLEN")}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type={"radio"}
                                                                label={t("drone.type-all")}
                                                                id={"allDrones"}
                                                                defaultChecked={typeFilter === "" ? true : false}
                                                                checked={typeFilter === "" ? true : false}
                                                                onChange={() => setTypeFilter("")}
                                                            />
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                                <Col style={{ margin: 0 }}>
                                                    <Form.Group controlId="formGroupBrand">
                                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("drone.brand")}</Form.Label>
                                                        <Form.Control value={brand} onChange={(v) => setModelsByBrand(v.target.value)} as="select">
                                                            <option value={""}>-----------</option>
                                                            {dronesBrand}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId="formGroupModel">
                                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("drone.model")}</Form.Label>
                                                        <Form.Control value={model} onChange={(v) => setModel(v.target.value)} as="select">
                                                            <option value={""}>-----------</option>
                                                            {dronesModel}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row style={{ margin: 0, display: "flex", justifyContent: "center" }}>
                                                <Button style={{ width: "47%", margin: '1.25rem 0rem 1.25rem 3%' }} onClick={() => loadDrones()}>
                                                    <FontAwesomeIcon icon={faFilter} />
                                                    <span style={{ marginLeft: '10px' }}>{t("pages.drones.filter.btn-filter")}</span>
                                                </Button>
                                                <Button variant="danger" style={{ width: "47%", margin: '1.25rem 0rem 1.25rem 3%' }} onClick={() => cleanFilters()}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                    <span style={{ marginLeft: '10px' }}>{t("pages.drones.filter.btn-cancel")}</span>
                                                </Button>
                                            </Row>
                                        </Form>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Row>
                {
                    dronesList.length === 0 ?
                        drones.status !== DronesStatus.Checking ?
                            <div><h5>{t("pages.drones.without-results")}</h5></div>
                            :
                            <div><h5>{t("pages.drones.loading")}</h5></div>
                        :
                        <>
                            <ListGroup>
                                {dronesList}
                            </ListGroup>
                            <Button style={{ width: "50%", margin: "2rem" }} onClick={() => { loadMoreDrones() }}>{t("pages.drones.btn-load")}</Button>
                        </>
                }
            </Card.Body>
        </Card>
    )
})

export default DronesColumn;