import L from "leaflet";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { addChildGroup } from "../../../api";
import { useTranslation } from "react-i18next";
import { GroupDataStoreContext } from "../../../stores/groupdata.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";

var groupsZonesLayerGroup: L.LayerGroup = new L.LayerGroup();

var drawControl = new L.Control.Draw({
  position: "topleft",
  draw: {
    polyline: false,
    marker: false,
    rectangle: false,
    circlemarker: false,
    polygon: false,
  },
});

export function ManagementGroupForm(props: {
  groupId: string;
  parentLocation: { center: { lat: number; lon: number }; radius: number };
  fncClosePopup: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [groupName, setGroupName] = useState<string>();
  const [groupLat, setGroupLat] = useState<number>();
  const [groupLon, setGroupLon] = useState<number>();
  const [groupRadius, setGroupRadius] = useState<number>();
  const [isMapReady, setIsMapReady] = useState(false);
  const [leafletMap, setLeafletMap] = useState<L.Map>();

  const groupdataStore = useContext(GroupDataStoreContext);

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (!groupName || !groupLat || !groupLon || !groupRadius) {
      alert(t("pages.management.groups.popup.alert-invalid-create"));
    } else {
      if (
        !(
          await addChildGroup(
            props.groupId,
            { center: { lat: groupLat, lon: groupLon }, radius: groupRadius },
            groupName
          )
        )[0]
      ) {
        alert(t("pages.management.groups.popup.alert-create-error"));
      } else {
        props.fncClosePopup();
      }
    }
  }

  function handleRemoveNewGroupLayer(map: L.Map) {
    let layers: Array<L.Layer> = [];
    map.eachLayer((layer: L.Layer) => {
      layers.push(layer);
    });
    layers.forEach((layer: any) => {
      if (
        layer.options &&
        layer.options.color &&
        layer.options.color == "#007bff"
      ) {
        map.removeLayer(layer);
      }
    });
  }

  useEffect(() => {
    if (!leafletMap) return;
    let layers: Array<L.Layer> = [];
    leafletMap.eachLayer((layer: L.Layer) => {
      layers.push(layer);
    });
    if (layers.length > 0) {
      layers.forEach((layer: any) => {
        if (
          layer.options &&
          layer.options.color &&
          layer.options.color == "#007bff"
        ) {
          if (
            groupLat != undefined &&
            groupLon != undefined &&
            groupRadius != undefined
          ) {
            leafletMap.removeLayer(layer);
            let updatedGroupZoneLayerAux = new L.Circle([groupLat, groupLon], {
              radius: groupRadius,
              color: "#007bff",
              fill: true,
            });
            groupsZonesLayerGroup.addLayer(updatedGroupZoneLayerAux);
          }
        }
      });
    }
  }, [groupLat, groupLon, groupRadius]);

  useEffect(() => {
    if (isMapReady) {
      return;
    }

    var map = new L.Map("newGroupMap");

    map.on("load", () => {
      setIsMapReady(true);
    });

    let defaultStyle =
      localStorage.getItem("mapStyle") !== undefined &&
      localStorage.getItem("mapStyle") !== null &&
      localStorage.getItem("mapStyle") !== ""
        ? localStorage.getItem("mapStyle")
        : "mapbox/streets-v11";

    const config = groupdataStore.group?.mapboxToken
      ? {
          accessToken: groupdataStore.group.mapboxToken,
          id: defaultStyle!,
          maxNativeZoom: 24,
          maxZoom: 24,
        }
      : {};
    new L.TileLayer(
      groupdataStore.group?.mapboxToken
        ? "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        ...config,
      }
    ).addTo(map);

    map.setView(
      [
        Number(groupdataStore.group?.location?.center.lat || 0),
        Number(groupdataStore.group?.location?.center.lon || 0),
      ],
      10
    );

    map.on(L.Draw.Event.CREATED, (event: any) => {
      handleRemoveNewGroupLayer(map);
      let newGroupZoneLayerAux = new L.Circle(
        [event.layer._latlng.lat, event.layer._latlng.lng],
        {
          radius: event.layer.options.radius,
          color: "#007bff",
          fill: true,
        }
      );
      groupsZonesLayerGroup.addLayer(newGroupZoneLayerAux);
      setGroupLat(event.layer._latlng.lat);
      setGroupLon(event.layer._latlng.lng);
      setGroupRadius(event.layer.options.radius);
    });

    let parentGroupZone = new L.Circle(
      [props.parentLocation.center.lat, props.parentLocation.center.lon],
      {
        radius: props.parentLocation.radius,
        color: "yellow",
        fill: false,
        weight: 4,
      }
    );

    groupsZonesLayerGroup.addLayer(parentGroupZone);
    groupsZonesLayerGroup.addTo(map);
    map.fitBounds(parentGroupZone.getBounds());
    map.addControl(drawControl);
    setLeafletMap(map);

    return () => {
      groupsZonesLayerGroup.clearLayers();
      if (leafletMap) {
        leafletMap.remove();
      }
    };
  }, []);

  function recenterMap() {
    if (leafletMap && groupdataStore.group) {
      leafletMap.setView(
        [
          groupdataStore.group?.location?.center.lat || 0,
          groupdataStore.group?.location?.center.lon || 0,
        ],
        10
      );
    }
  }

  return (
    <Row style={{ margin: 0, padding: 0, minWidth: "1000px" }}>
      <Form
        style={{ width: "50%", paddingRight: "2rem" }}
        id="managementGroupForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formGroupName">
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.groups.popup.label-name")}
          </Form.Label>
          <Form.Control
            value={groupName}
            onChange={(v) => setGroupName(v.target.value)}
            type="text"
            placeholder={t(
              "pages.management.groups.popup.placeholder-name"
            )}
          />
        </Form.Group>
        <Row style={{ margin: "0", justifyContent: "space-between" }}>
          <Form.Group
            controlId="formGroupParentLocation"
            style={{ width: "45%" }}
          >
            <Form.Label style={{ fontWeight: "bold" }}>
              {t(
                "pages.management.groups.popup.label-parent-group-location"
              )}
            </Form.Label>
            <Row
              style={{
                width: "100%",
                margin: ".5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label style={{}}>
                {t("pages.management.groups.popup.label-lat")}
              </Form.Label>
              <Form.Control
                style={{ width: "100px", textAlign: "right" }}
                value={props.parentLocation.center.lat.toFixed(4)}
                type="number"
                disabled
              />
            </Row>
            <Row
              style={{
                width: "100%",
                margin: ".5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label style={{}}>
                {t("pages.management.groups.popup.label-lon")}
              </Form.Label>
              <Form.Control
                style={{ width: "100px", textAlign: "right" }}
                value={props.parentLocation.center.lon.toFixed(4)}
                type="number"
                disabled
              />
            </Row>
            <Row
              style={{
                width: "100%",
                margin: ".5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label style={{}}>
                {t("pages.management.groups.popup.label-radius")}
              </Form.Label>
              <Form.Control
                style={{ width: "100px", textAlign: "right" }}
                value={(props.parentLocation.radius / 1000).toFixed(2)}
                type="number"
                disabled
              />
            </Row>
          </Form.Group>
          <Form.Group
            controlId="formGroupParentLocation"
            style={{ width: "45%" }}
          >
            <Form.Label style={{ fontWeight: "bold" }}>
              {t("pages.management.groups.popup.label-group-location")}
            </Form.Label>
            <Row
              style={{
                width: "100%",
                margin: ".5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label style={{}}>
                {t("pages.management.groups.popup.label-lat")}
              </Form.Label>
              <Form.Control
                style={{ width: "100px", textAlign: "right" }}
                value={groupLat ? groupLat.toFixed(4) : undefined}
                onChange={(e) => {
                  setGroupLat(parseFloat(e.target.value));
                }}
                type="number"
              />
            </Row>
            <Row
              style={{
                width: "100%",
                margin: ".5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label style={{}}>
                {t("pages.management.groups.popup.label-lon")}
              </Form.Label>
              <Form.Control
                style={{ width: "100px", textAlign: "right" }}
                value={groupLon ? groupLon.toFixed(4) : undefined}
                onChange={(e) => {
                  setGroupLon(parseFloat(e.target.value));
                }}
                type="number"
              />
            </Row>
            <Row
              style={{
                width: "100%",
                margin: ".5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label style={{}}>
                {t("pages.management.groups.popup.label-radius")}
              </Form.Label>
              <Form.Control
                style={{ width: "100px", textAlign: "right" }}
                value={
                  groupRadius ? (groupRadius / 1000).toFixed(2) : undefined
                }
                onChange={(e) => {
                  setGroupRadius(parseFloat(e.target.value) * 1000);
                }}
                type="number"
              />
            </Row>
          </Form.Group>
        </Row>
        <p style={{ textAlign: "justify" }}>
          {t("pages.management.groups.popup.info")}
        </p>
      </Form>
      <div
        id="newGroupMap"
        style={{ height: "400px", width: "50%", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "130px",
            left: "10px",
            zIndex: 510,
            cursor: "pointer",
            width: "33px",
            height: "33px",
            display: "flex",
            borderRadius: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <FontAwesomeIcon
            icon={faCrosshairs}
            style={{ fontSize: "large" }}
            onClick={() => recenterMap()}
          />
        </div>
      </div>
    </Row>
  );
}
