import React, { useState, useContext, SyntheticEvent, useEffect } from 'react';


import { ModelsDataStoreContext } from '../../../stores/models.store';

import { observer } from 'mobx-react-lite';
import { RegistersDataStoreContext, FilterStatus } from '../../../stores/registersdata.store';
import { ZonesDataStoreContext } from '../../../stores/zonesdata.store';
import { DetectorsStoreContext } from '../../../stores/detectors.store';
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faDownload, faTimes, faFilter, faEye } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RegistersFilterForm = observer((props: any) => {

    const [t, i18n] = useTranslation("common");

    const registersDataStore = useContext(RegistersDataStoreContext);
    const zonesDataStore = useContext(ZonesDataStoreContext);
    const modelsDataStore = useContext(ModelsDataStoreContext);
    const detectorsDataStore = useContext(DetectorsStoreContext);


    const [sn, setSN] = useState("");
    const [dateIni, setDateIni] = useState("");
    const [dateFin, setDateFin] = useState("");
    const [hourIni, setHourIni] = useState("");
    const [hourFin, setHourFin] = useState("");
    const [daysOfTheWeek, setDayOfTheWeek]: any = useState([]);
    const [zones, setZones]: any = useState([]);
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [downloadExcelParams, setDownloadExcelParams]: any = useState("");
    const [detectors, setDetectors]: any = useState([]);
    const [selectAll, setSelectAll] = useState<boolean>(false)



    useEffect(() => {
        setDateIni("");
        setDateFin("");
        setHourIni("");
        setHourFin("");
        setDayOfTheWeek([]);
        setZones([]);
        setBrand("");
        setModel("");
        setDetectors([]);
        setSelectAll(false);
        //registersDataStore.queryParams = '';
        //registersDataStore.getRegisters(registersDataStore.queryParams);
        modelsDataStore.getModels();
    }, [])


    let dicOfDays: any = { "Lunes": "Mon", "Martes": "Tue", "Miércoles": "Wen", "Jueves": "Thu", "Viernes": "Fri", "Sábado": "Sat", "Domingo": "Sun", "Todos": "All" };

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        registersDataStore.queryParams = '';
        registersDataStore.queryParams += '?'
        if (sn) {
            registersDataStore.queryParams += 'sn=' + sn + '&';
        }
        if (dateIni) {
            /*var auxDateIni = dateIni - 120;
            if(auxDateIni < 0){
                registersDataStore.queryParams += 'dateIni=' + (1440 + auxDateIni) + '&';
            } else{
                registersDataStore.queryParams += 'dateIni=' + dateIni + '&';
            }*/
            registersDataStore.queryParams += 'dateIni=' + dateIni + '&';
        }
        if (dateFin) {
            registersDataStore.queryParams += 'dateFin=' + dateFin + '&';
        }
        if (dateIni && dateFin) {
            if (dateIni > dateFin) {
                alert("Fechas introducidas incorrectas");
                registersDataStore.queryParams = '';
                registersDataStore.queryParams += '?';
                return
            }
        }
        var hoursIni, hoursFin, minutesIni, minutesFin;
        if (hourIni) {
            hoursIni = Number(hourIni.substring(0, 2)) * 60 - 120;
            hoursIni = hoursIni < 0 ? (24 * 60) + hoursIni : hoursIni;
            minutesIni = Number(hourIni.substring(3, 5));
            registersDataStore.queryParams += 'hourIni=' + (hoursIni + minutesIni) + '&';
        }
        if (hourFin) {
            hoursFin = Number(hourFin.substring(0, 2)) * 60 - 120;
            hoursFin = hoursFin < 0 ? (24 * 60) + hoursFin : hoursFin;
            minutesFin = Number(hourFin.substring(3, 5));
            registersDataStore.queryParams += 'hourFin=' + (hoursFin + minutesFin) + '&';
        }
        if (hoursIni && hoursFin && minutesIni && minutesFin) {
            if ((hoursIni > hoursFin || (hoursIni == hoursFin && minutesIni >= minutesFin)) && ((dateIni && dateFin && (dateIni == dateFin)) || (!dateIni && !dateFin))) {
                alert("Horas introducidas incorrectas");
                registersDataStore.queryParams = '';
                registersDataStore.queryParams += '?';
                return;
            }
        }
        if (daysOfTheWeek) {
            for (var i = 0; i < daysOfTheWeek.length; i++) {
                registersDataStore.queryParams += 'daysOfTheWeek=' + dicOfDays[daysOfTheWeek[i]] + '&';
            }
        }
        if (zones.length !== 0) {
            for (var i = 0; i < zones.length; i++) {
                registersDataStore.queryParams += 'zones=' + zones[i] + '&';
            }
        }
        if (detectors.length !== 0) {
            for (var i = 0; i < detectors.length; i++) {
                registersDataStore.queryParams += 'detectors=' + detectors[i] + '&';
            }
        }
        if (brand) {
            registersDataStore.queryParams += 'brand=' + brand + '&';
        }
        if (model) {
            registersDataStore.queryParams += 'model=' + model + '&';
        }

        registersDataStore.getRegisters(registersDataStore.queryParams);
        registersDataStore.downloadExcelStatus = FilterStatus.Filtered;
        setDownloadExcelParams(registersDataStore.queryParams);
    }

    function cleanFilters() {
        setSN("");
        setDateIni("");
        setDateFin("");
        setHourIni("");
        setHourFin("");
        setDayOfTheWeek([]);
        setZones([]);
        setDetectors([]);
        setBrand("");
        setModel("");
    }

    async function downloadFile() {
        if (registersDataStore.downloadExcelStatus === 0) {
            let downloadDateFin = new Date();
            let downloadDateIni = new Date();
            downloadDateIni.setDate(downloadDateIni.getDate() - 30);
    
            
    
            // Construct the download parameters including timezoneOffset
            let downloadExcelParams = `?dateIni=${downloadDateIni.toISOString()}&dateFin=${downloadDateFin.toISOString()}`;
            
            // Set the params
            setDownloadExcelParams(downloadExcelParams);  // Save to state if needed
        }
    
        props.onDownloadStarted();
        
        // Use the constructed params in the API call
        await registersDataStore.getExcel(downloadExcelParams);
        
        props.onDownloadEnded();
    }
    
    

    function setDays(value: String) {
        let idx = daysOfTheWeek.indexOf(value);
        let actualArray = [...daysOfTheWeek];
        if (idx >= 0) {
            if (actualArray.length === 1) {
                actualArray = [];
            } else {
                actualArray.splice(idx, 1);
            }
        } else {
            actualArray.push(value);
        }
        setDayOfTheWeek(actualArray);
    }


    function setTravessedZones(value: String) {
        let idx = zones.indexOf(value);
        let actualArray = [...zones];
        if (idx >= 0) {
            if (actualArray.length === 1) {
                actualArray = [];
            } else {
                actualArray.splice(idx, 1);
            }
        } else {
            actualArray.push(value);
        }
        setZones(actualArray);
    }

    var groupZones = []
    for (var i = 0; i < zonesDataStore.zones.length; i++) {
        let zone = toJS(zonesDataStore.zones[i]);
        groupZones.push(
            <option value={zone._id} key={zone._id}>{zone.name}</option>
        )
    }



    var dronesBrand: any = [];
    var uniqueBrand: any = {};
    var dronesModel: any = [];
    for (var j = 0; j < modelsDataStore.models.length; j++) {
        let model = toJS(modelsDataStore.models[j]);
        if (!uniqueBrand[model.brand]) {
            uniqueBrand[model.brand] = [];
            dronesBrand.push(
                <option value={model.brand} key={model.brand}>{model.brand}</option>
            )
        }
        uniqueBrand[model.brand].push(model.model);
        if (!brand) {
            model.model && dronesModel.push(
                <option value={model.model} key={model.model}>{model.model}</option>
            )
        }
    }

    dronesBrand.sort((a: any, b: any) => {
        if (a.props.value?.toUpperCase() > b.props.value?.toUpperCase()) { return 1 }
        if (a.props.value?.toUpperCase() < b.props.value?.toUpperCase()) { return -1 }
        return 1
    })

    if (brand) {
        for (var i = 0; i < uniqueBrand[brand].length; i++) {
            dronesModel.push(
                <option value={uniqueBrand[brand][i]}>{uniqueBrand[brand][i]}</option>
            )
        }
    }

    dronesModel.sort((a: any, b: any) => {
        if (a.props.children?.toUpperCase() > b.props.children?.toUpperCase()) { return 1 }
        if (a.props.children?.toUpperCase() < b.props.children?.toUpperCase()) { return -1 }
        return 1
    })

    function setModelsByBrand(brand: any) {
        setBrand(brand);
        setModel("");
    }

    function setDetectorsHandle(value: String) {
        let idx = detectors.indexOf(value);
        let actualArray = [...detectors];
        if (idx >= 0) {
            if (actualArray.length === 1) {
                actualArray = [];
            } else {
                actualArray.splice(idx, 1);
            }
        } else {
            actualArray.push(value);
        }
        setDetectors(actualArray);
    }

    var detectorsGroup = []
    for (var i = 0; i < detectorsDataStore.detectors.length; i++) {
        let detector = toJS(detectorsDataStore.detectors[i]);
        detectorsGroup.push(
            <option value={detector._id} key={detector._id}>{detector.name}</option>
        )
    }

    useEffect(() => {
        if (selectAll && registersDataStore.activeRegisters.length !== registersDataStore.registers.length) setSelectAll(false);
        else if (!selectAll && registersDataStore.activeRegisters.length === registersDataStore.registers.length && registersDataStore.registers.length > 0) setSelectAll(true);
    }, [JSON.stringify(registersDataStore.activeController)])


    function handleShowAll(newState: boolean) {
        if (newState) {
            registersDataStore.activeRegisters = [];
            for (var i = 0; i < registersDataStore.registers.length; i++) {
                let register = registersDataStore.registers[i];
                registersDataStore.activeController[i] = true;
                registersDataStore.activeRegisters.push({ id: i, detection: register.route, drone: register.drone, center: register.center });
            }
        } else {
            for (var i = 0; i < registersDataStore.registers.length; i++) {
                registersDataStore.activeController[i] = false;
            }
            registersDataStore.activeRegisters = []
        }
        setSelectAll(newState);
    }

    return (
        <Accordion>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="-1" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <OverlayTrigger
                        key="selectAll"
                        placement='bottom'
                        overlay={
                            <Tooltip id={"selectAll"}>
                                {t("pages.detections.filters.btn-select-all-hover")}
                            </Tooltip>
                        }
                    >
                        <Button
                            onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); handleShowAll(!selectAll); }}
                            style={{ backgroundColor: selectAll ? "" : "transparent", borderColor: selectAll ? "" : "gray" }} >
                            <FontAwesomeIcon icon={faEye} style={{ color: selectAll ? "white" : "gray" }} />
                        </Button>
                    </OverlayTrigger>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h5 style={{ margin: "0", marginRight: "20px" }}>{t("pages.detections.filters.header")}</h5>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="-1">
                    <Card.Body style={{ padding: '1.25rem 1.25rem' }}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formGroupSN">
                                <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.identifier")}</Form.Label>
                                <Form.Control value={sn} onChange={(v) => setSN(v.target.value)} type="text" placeholder={t("pages.detections.filters.identifier-placeholder")} />
                            </Form.Group>
                            <Form.Group controlId="formGroupDetectors">
                                <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.detectors")}</Form.Label>
                                <Form.Control value={detectors} onClick={(v: any) => setDetectorsHandle(v.target.value)} as="select" multiple>
                                    {detectorsGroup}
                                </Form.Control>
                            </Form.Group>
                            <Row style={{ margin: 0 }}>
                                <Col style={{ paddingLeft: 0 }}>
                                    <Form.Group controlId="formGroupBrand">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.brand")}</Form.Label>
                                        <Form.Control value={brand} onChange={(v) => setModelsByBrand(v.target.value)} as="select">
                                            <option value={""}>-----------</option>
                                            {dronesBrand}
                                        </Form.Control>
                                    </Form.Group>

                                </Col>
                                <Col style={{ paddingRight: 0 }}>
                                    <Form.Group controlId="formGroupModel">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.model")}</Form.Label>
                                        <Form.Control value={model} onChange={(v) => setModel(v.target.value)} as="select">
                                            <option value={""}>-----------</option>
                                            {dronesModel}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: 0 }}>
                                <Col style={{ paddingLeft: 0 }}>
                                    <Form.Group controlId="formGroupDateIni">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.start-date")}</Form.Label>
                                        <Form.Control value={dateIni} onChange={(v) => setDateIni(v.target.value)} type="date" />
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingRight: 0 }}>
                                    <Form.Group controlId="formGroupDateFin">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.end-date")}</Form.Label>
                                        <Form.Control value={dateFin} type="date" onChange={(v) => setDateFin(v.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: 0 }}>
                                <Col style={{ paddingLeft: 0 }}>
                                    <Form.Group controlId="formGroupHourIni">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.start-time")}</Form.Label>
                                        <Form.Control value={hourIni} onChange={(v) => setHourIni(v.target.value)} type="time" />
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingRight: 0 }}>
                                    <Form.Group controlId="formGroupHourFin">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.end-time")}</Form.Label>
                                        <Form.Control value={hourFin} onChange={(v) => setHourFin(v.target.value)} type="time" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: 0 }}>
                                <Col style={{ paddingLeft: 0 }}>
                                    <Form.Group controlId="formGroupDaysOfTheWeek">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.days")}</Form.Label>
                                        <Form.Control value={daysOfTheWeek} onClick={(v: any) => setDays(v.target.value)} as="select" multiple>
                                            <option>{t("pages.detections.filters.days-mon")}</option>
                                            <option>{t("pages.detections.filters.days-tue")}</option>
                                            <option>{t("pages.detections.filters.days-wed")}</option>
                                            <option>{t("pages.detections.filters.days-thu")}</option>
                                            <option>{t("pages.detections.filters.days-fri")}</option>
                                            <option>{t("pages.detections.filters.days-sat")}</option>
                                            <option>{t("pages.detections.filters.days-sun")}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingRight: 0 }}>
                                    <Form.Group controlId="formGroupZones">
                                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.detections.filters.zones")}</Form.Label>
                                        <Form.Control value={zones} onClick={(v: any) => setTravessedZones(v.target.value)} as="select" multiple>
                                            {groupZones}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: 0 }}>
                                <Button style={{ width: "47%", margin: '1.25rem 3% 1.25rem 0rem' }} type="submit">
                                    <FontAwesomeIcon icon={faFilter} />
                                    <span style={{ marginLeft: '10px' }}>{t("pages.detections.filters.btn-filter")}</span>
                                </Button>
                                <Button variant="danger" style={{ width: "47%", margin: '1.25rem 0rem 1.25rem 3%' }} onClick={() => cleanFilters()}>
                                    <FontAwesomeIcon icon={faTimes} />
                                    <span style={{ marginLeft: '10px' }}>{t("pages.detections.filters.btn-cancel")}</span>
                                </Button>
                            </Row>
                            {
                                registersDataStore.status == 3 ?
                                    <Button block onClick={() => downloadFile()}>
                                        <FontAwesomeIcon icon={faDownload} />
                                        <span style={{ marginLeft: '10px' }}>{t("pages.detections.filters.btn-download-excel")}</span>
                                    </Button>
                                    :
                                    null
                            }
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
});

export default RegistersFilterForm;