import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { observer } from "mobx-react-lite";
import { GroupDataStoreContext } from "../../../stores/groupdata.store";
import Col from "react-bootstrap/Col";
import {
  Form,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faEdit,
  faTrash,
  faUser,
  faSearch,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  IGroup,
  IGroupDetector,
  IGroupInhibitor,
  IGroupUser,
} from "../../../interfaces/group.interface";
import { ManagementPopup } from "../components/managementPopup";
import { ManagementGroupForm } from "../components/groupForm";
import L from "leaflet";
import {
  deleteChildGroup,
  deleteDetector,
  deleteInhibitor,
  deleteUser,
  removeDetectorFromGroup,
  removeInhibitorFromGroup,
} from "../../../api";
import { ManagementUserForm } from "../components/userForm";
import { UserDataStoreContext } from "../../../stores/userdata.store";
import { ManagementGroupDetectorForm } from "../components/groupDetectorForm";
import { ManagementGroupInhibitorForm } from "../components/groupInhibitorForm";
import { ManagementCreateDetectorForm } from "../components/createDetectorForm";
import { ManagementCreateInhibitorForm } from "../components/createInhibitorForm";
import { ManagementEditUserForm } from "../components/editUserForm";
import { ManagementEditDetectorForm } from "../components/editDetectorForm";
import { ManagementEditInhibitorForm } from "../components/editInhibitorForm";

const inh_port_icon = require("../../../images/inh_port_icon.png");
const inh_est_icon = require("../../../images/inh_est_icon.png");
const det_port_icon = require("../../../images/det_port_icon.png");
const det_est_icon = require("../../../images/det_est_icon.png");

const rolesDictionary: { [key: string]: string } = {
  ADMIN: "ADMINISTRADOR DEPARTAMENTAL",
  SUPERUSER: "ADMINISTRADOR LOCAL",
  ADVANCEDUSER: "USUARIO AVANZADO",
  USER: "USUARIO",
};

function getFlatGroupList(groups: IGroup[]): Array<{
  id: string;
  name: string;
  detectors: Array<string>;
  inhibitors: Array<string>;
}> {
  const result: Array<{
    id: string;
    name: string;
    detectors: Array<string>;
    inhibitors: Array<string>;
  }> = [];

  const traverse = (group: IGroup) => {
    if (group && !result.find((g) => group._id === g.id)) {
      result.push({
        id: group._id,
        name: group.name,
        detectors: group.devices.detectors.map((detector) => detector._id),
        inhibitors: group.devices.inhibitors.map((inhibitor) => inhibitor._id),
      });
    }
    if (group && group.children) {
      group.children.forEach((child) => traverse(child));
    }
  };

  groups.forEach((group) => traverse(group));
  return result;
}

const ManagementPage = observer(() => {
  const [t, i18n] = useTranslation("common");

  const groupdataStore = useContext(GroupDataStoreContext);
  const [activeGroup, setActiveGroup] = useState<IGroup | null>(
    groupdataStore.children
  );
  const [activeGroupParent, setActiveGroupParent] = useState<IGroup | null>();
  const [groupsList, setGroupsList] = useState<
    Array<{
      id: string;
      name: string;
      detectors: Array<string>;
      inhibitors: Array<string>;
    }>
  >([]);
  const [popupContent, setPopupContent] = useState<{
    showPopup: boolean;
    fncClosePopup?: Function;
    fncConfirm?: Function;
    title?: string;
    formId?: string;
    content?: React.ReactNode;
  }>({
    showPopup: false,
  });
  const [isMapReady, setIsMapReady] = useState(false);
  const [leafletMap, setLeafletMap] = useState<L.Map>();

  async function handleClosePopup() {
    setPopupContent({ showPopup: false });
  }

  async function handleCreateChildGroup() {
    await loadData();
    handleClosePopup();
  }

  async function handleDeleteChildGroup(childGroup: IGroup) {
    if (
      childGroup.users.length > 0 ||
      childGroup.children.length > 0 ||
      childGroup.devices.detectors.length > 0 ||
      childGroup.devices.inhibitors.length > 0
    ) {
      alert(t("pages.management.groups.alert-invalid-delete"));
    } else {
      if (!(await deleteChildGroup(childGroup._id))[0]) {
        alert(t("pages.management.groups.alert-invalid-delete"));
      } else {
        await loadData();
        setActiveGroup(groupdataStore.children);
      }
    }
    handleClosePopup();
  }

  async function loadData() {
    await groupdataStore.getChildren();

    function findActiveGroup(
      children: Array<IGroup>,
      childId: string
    ): IGroup | undefined {
      for (let group of children) {
        if (group._id.toString() === childId.toString()) return group;
        let activeGroup = findActiveGroup(group.children, childId);
        if (activeGroup) return activeGroup;
      }
      return;
    }
    
    let currentGroup;
    if (activeGroup) {
      currentGroup = findActiveGroup(
        [groupdataStore.children],
        activeGroup._id
      );
    }
    setActiveGroup(currentGroup || groupdataStore.children);
  }

  useEffect(() => {
    setGroupsList(getFlatGroupList([groupdataStore.children]));
  }, [groupdataStore.children]);

  useEffect(() => {
    if (!isMapReady || !activeGroup || !leafletMap) return;

    let layers: Array<L.Layer> = [];
    leafletMap.eachLayer((layer: L.Layer) => {
      layers.push(layer);
    });
    if (layers.length > 0) {
      layers.forEach((layer: any) => {
        if (
          layer.options &&
          layer.options.color &&
          layer.options.color == "yellow"
        ) {
          leafletMap.removeLayer(layer);
        }
      });
    }

    let groupZone = new L.Circle(
      [activeGroup.location.center.lat, activeGroup.location.center.lon],
      {
        radius: activeGroup.location.radius,
        color: "yellow",
        fill: false,
        weight: 4,
      }
    );
    groupZone.addTo(leafletMap);
    leafletMap.fitBounds(groupZone.getBounds());
  }, [activeGroup, leafletMap]);

  useEffect(() => {
    if (!activeGroup || leafletMap || isMapReady) return;

    var map = new L.Map("map");
    map.on("load", () => {
      setIsMapReady(true);
    });

    map.setView(
      localStorage.getItem("mapCenterLat")
        ? [
            Number(localStorage.getItem("mapCenterLat")),
            Number(localStorage.getItem("mapCenterLon")),
          ]
        : [40.239748, -4.239292],
      localStorage.getItem("mapZoom")
        ? Number(localStorage.getItem("mapZoom"))
        : 6
    );

    let defaultStyle =
      localStorage.getItem("mapStyle") !== undefined &&
      localStorage.getItem("mapStyle") !== null &&
      localStorage.getItem("mapStyle") !== ""
        ? localStorage.getItem("mapStyle")
        : "mapbox/streets-v11";

    const config = groupdataStore.group?.mapboxToken
      ? {
          accessToken: groupdataStore.group.mapboxToken,
          id: defaultStyle!,
          maxNativeZoom: 24,
          maxZoom: 24,
        }
      : {};
    new L.TileLayer(
      groupdataStore.group?.mapboxToken
        ? "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        ...config,
      }
    ).addTo(map);
    //map.setMaxBounds(L.latLngBounds(L.latLng(Number(process.env.REACT_APP_MAP_MAX_LAT || 0), Number(process.env.REACT_APP_MAP_MIN_LON || 0)), L.latLng(Number(process.env.REACT_APP_MAP_MIN_LAT || 0), Number(process.env.REACT_APP_MAP_MAX_LON || 0))))

    setLeafletMap(map);
  }, [activeGroup, leafletMap]);

  useEffect(() => {
    loadData();

    return () => {
      if (leafletMap) {
        leafletMap.remove();
      }
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", padding: "2rem" }}>
      {activeGroup ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ManagementPopup
            show={popupContent["showPopup"]}
            handleCancel={popupContent["fncClosePopup"]!}
            handleConfirm={popupContent["fncConfirm"]!}
            title={popupContent["title"]!}
            formId={popupContent["formId"]}
          >
            {popupContent["content"]}
          </ManagementPopup>
          <div
            style={{ width: "60%", minWidth: "600px", paddingRight: "1rem" }}
          >
            <Col style={{ height: "100%" }}>
              <div style={{ width: "100%", height: "5%" }}>
                <h4 style={{ margin: "0" }}>{activeGroup.name}</h4>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "45%",
                  display: "flex",
                  paddingTop: "1rem",
                }}
              >
                <Col
                  style={{
                    height: "100%",
                    width: "40%",
                    padding: "1rem",
                    overflowY: "auto",
                  }}
                >
                  <Col style={{ padding: 0 }}>
                    <h5>{t("pages.management.info.detection-systems")}</h5>
                    <div>
                      <img
                        src={det_est_icon}
                        style={{
                          height: "40px",
                          width: "40px",
                          margin: "0 5px",
                        }}
                      />
                      <span>
                        {t("pages.management.info.fixed-detectors")}:{" "}
                        {activeGroup.devices.detectors.filter(
                          (detector) => detector.isActive && !detector.isMobile
                        ).length +
                          "/" +
                          activeGroup.devices.detectors.filter(
                            (detector) => !detector.isMobile
                          ).length}
                      </span>
                    </div>
                    <div>
                      <img
                        src={det_port_icon}
                        style={{
                          height: "40px",
                          width: "40px",
                          margin: "0 5px",
                        }}
                      />
                      <span>
                        {t("pages.management.info.mobile-detectors")}:{" "}
                        {activeGroup.devices.detectors.filter(
                          (detector) => detector.isActive && detector.isMobile
                        ).length +
                          "/" +
                          activeGroup.devices.detectors.filter(
                            (detector) => detector.isMobile
                          ).length}
                      </span>
                    </div>
                  </Col>
                  <Col style={{ padding: 0, marginTop: "1rem" }}>
                    <h5>{t("pages.management.info.inhibition-systems")}</h5>
                    <div>
                      <img
                        src={inh_est_icon}
                        style={{
                          height: "40px",
                          width: "40px",
                          margin: "0 5px",
                        }}
                      />
                      <span>
                        {t("pages.management.info.fixed-inhibitors")}:{" "}
                        {activeGroup.devices.inhibitors.filter(
                          (inhibitor) =>
                            inhibitor.isActive && !inhibitor.isMobile
                        ).length +
                          "/" +
                          activeGroup.devices.inhibitors.filter(
                            (inhibitor) => !inhibitor.isMobile
                          ).length}
                      </span>
                    </div>
                    <div>
                      <img
                        src={inh_port_icon}
                        style={{
                          height: "40px",
                          width: "40px",
                          margin: "0 5px",
                        }}
                      />
                      <span>
                        {t("pages.management.info.mobile-inhibitors")}:{" "}
                        {activeGroup.devices.inhibitors.filter(
                          (inhibitor) =>
                            inhibitor.isActive && inhibitor.isMobile
                        ).length +
                          "/" +
                          activeGroup.devices.inhibitors.filter(
                            (inhibitor) => inhibitor.isMobile
                          ).length}
                      </span>
                    </div>
                  </Col>
                  <Col style={{ padding: 0, marginTop: "1rem" }}>
                    <h5>{t("pages.management.info.users")}</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "13px",
                          marginRight: "20px",
                        }}
                      />
                      <span>
                        {t("pages.management.info.users")}:{" "}
                        {activeGroup.users.length}
                      </span>
                    </div>
                  </Col>
                </Col>
                <div id="map" style={{ height: "100%", width: "60%" }} />
              </div>
              <Card
                style={{
                  width: "100%",
                  height: "calc(50% - 1rem)",
                  textAlign: "center",
                  overflowY: "auto",
                  marginTop: "1rem",
                }}
              >
                <Card.Header
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ margin: "0" }}>
                    {t("pages.management.headers.groups")}
                  </h5>
                </Card.Header>
                <Card.Body>
                  <div style={{ width: "100%", height: "100%" }}>
                    <ListGroup>
                      <GroupItem
                        group={groupdataStore.children}
                        groupLevel={0}
                        activeGroupId={activeGroup._id}
                        parentGroup={groupdataStore.group}
                        setActiveGroupParent={setActiveGroupParent}
                        setActiveGroup={setActiveGroup}
                        setPopupContent={setPopupContent}
                        handleClosePopup={handleClosePopup}
                        handleDeleteChildGroup={handleDeleteChildGroup}
                      />
                    </ListGroup>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "1rem",
                      }}
                    >
                      <Button
                        style={{ width: "200px", margin: ".5rem" }}
                        type="submit"
                        onClick={() => {
                          setPopupContent({
                            showPopup: true,
                            fncClosePopup: handleClosePopup,
                            fncConfirm: handleCreateChildGroup,
                            title: t("pages.management.groups.popup.header"),
                            formId: "managementGroupForm",
                            content: (
                              <ManagementGroupForm
                                fncClosePopup={handleCreateChildGroup}
                                groupId={activeGroup?._id}
                                parentLocation={activeGroup?.location}
                              />
                            ),
                          });
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          {t("pages.management.groups.btn-add-group")}
                        </span>
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <RightColumn
            groupId={activeGroup?._id}
            groupName={activeGroup?.name}
            users={activeGroup.users}
            detectors={activeGroup.devices.detectors}
            parentDetectors={
              activeGroupParent ? activeGroupParent.devices.detectors : []
            }
            inhibitors={activeGroup.devices.inhibitors}
            parentInhibitors={
              activeGroupParent ? activeGroupParent.devices.inhibitors : []
            }
            loadData={loadData}
            setPopupContent={setPopupContent}
            handleClosePopup={handleClosePopup}
            groupsList={groupsList}
          />
        </div>
      ) : (
        <span>ERROR</span>
      )}
    </div>
  );
});

const GroupItem = (props: {
  group: IGroup;
  groupLevel: number;
  activeGroupId: string;
  parentGroup: IGroup;
  setActiveGroup: Function;
  setActiveGroupParent: Function;
  setPopupContent: Function;
  handleClosePopup: Function;
  handleDeleteChildGroup: Function;
}) => {
  const [t, i18n] = useTranslation("common");

  const [showChildren, setShowChildren] = useState<boolean>(true);

  return (
    <>
      {props.group ? (
        <ListGroupItem
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: ".5rem",
            marginLeft: "calc( " + 25 * props.groupLevel + "px )",
            border: "1px solid #cccccc",
            backgroundColor:
              props.activeGroupId === props.group._id ? "#dddddd" : "white",
            cursor:
              props.activeGroupId === props.group._id ? "default" : "pointer",
          }}
          key={"group_" + props.group._id}
          onClick={() => {
            if (props.activeGroupId !== props.group._id) {
              props.setActiveGroup(props.group);
              props.setActiveGroupParent(props.parentGroup);
            }
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={showChildren ? faAngleDown : faAngleRight}
                style={{
                  marginRight: "1rem",
                  cursor: "pointer",
                  width: "16px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowChildren(!showChildren);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1rem",
                }}
              >
                <span style={{ textAlign: "left", fontSize: "18px" }}>
                  <b>{props.group.name}</b>
                </span>
                <div>
                  <span>
                    {t("pages.management.headers.detectors")}:{" "}
                    <b>{props.group.devices.detectors.length}</b>
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {t("pages.management.headers.inhibitors")}:{" "}
                    <b>{props.group.devices.inhibitors.length}</b>
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {t("pages.management.headers.users")}:{" "}
                    <b>{props.group.users.length}</b>
                  </span>
                </div>
              </div>
            </div>
            <div>
              {props.groupLevel > 0 ? (
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ margin: "0 .5rem", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.setPopupContent({
                      showPopup: true,
                      fncClosePopup: props.handleClosePopup,
                      fncConfirm: () => {
                        props.handleDeleteChildGroup(props.group);
                      },
                      title: t(
                        "pages.management.groups.delete-confirmation-header"
                      ),
                      content: (
                        <div>
                          {t(
                            "pages.management.groups.delete-confirmation-content"
                          )}{" "}
                          <b>{props.group.name}</b>
                        </div>
                      ),
                    });
                  }}
                />
              ) : null}
            </div>
          </div>
        </ListGroupItem>
      ) : null}
      {showChildren && props.group && props.group.children
        ? props.group.children.map((child) => (
            <GroupItem
              group={child}
              groupLevel={props.groupLevel + 1}
              activeGroupId={props.activeGroupId}
              setActiveGroupParent={props.setActiveGroupParent}
              parentGroup={props.group}
              setActiveGroup={props.setActiveGroup}
              setPopupContent={props.setPopupContent}
              handleClosePopup={props.handleClosePopup}
              handleDeleteChildGroup={props.handleDeleteChildGroup}
            />
          ))
        : null}
    </>
  );
};

const RightColumn = (props: {
  groupId: string;
  groupName: string;
  users: Array<IGroupUser>;
  detectors: Array<IGroupDetector>;
  parentDetectors: Array<IGroupDetector>;
  inhibitors: Array<IGroupInhibitor>;
  parentInhibitors: Array<IGroupInhibitor>;
  loadData: Function;
  setPopupContent: Function;
  handleClosePopup: Function;
  groupsList: Array<{
    id: string;
    name: string;
    detectors: Array<string>;
    inhibitors: Array<string>;
  }>;
}) => {
  const [t, i18n] = useTranslation("common");

  const [filterUsers, setFilterUsers] = useState<string>("");
  const [filterDetectors, setFilterDetectors] = useState<string>("");
  const [filterInhibitors, setFilterInhibitors] = useState<string>("");

  const userdataStore = useContext(UserDataStoreContext);

  async function handleCreateUser() {
    await props.loadData();
    props.handleClosePopup();
  }

  async function handleEditUser() {
    await props.loadData();
    props.handleClosePopup();
  }

  async function handleDeleteUser(userId: string) {
    if (userdataStore.user._id == userId) {
      alert(t("pages.management.users.alert-invalid-delete"));
    } else {
      if (!(await deleteUser(userId))[0]) {
        alert(t("pages.management.users.alert-delete-error"));
      } else {
        await props.loadData();
      }
    }
    props.handleClosePopup();
  }

  async function handleAddDetectors() {
    await props.loadData();
    props.handleClosePopup();
  }

  async function handleEditDetector() {
    await props.loadData();
    props.handleClosePopup();
  }

  async function handleRemoveDetector(detectorId: string) {
    if (userdataStore.user.role !== "SUPERADMIN") {
      alert(t("pages.management.detectors.alert-delete-error"));
    } else {
      if (!(await deleteDetector(detectorId))[0]) {
        alert(t("pages.management.detectors.alert-delete-error"));
      } else {
        await props.loadData();
      }
    }
    props.handleClosePopup();
  }

  async function handleRemoveDetectorFromGroup(
    groupId: string,
    detectorId: string
  ) {
    if (userdataStore.user.group === groupId) {
      alert(t("pages.management.detectors.alert-delete-error"));
    } else {
      if (!(await removeDetectorFromGroup(groupId, detectorId))[0]) {
        alert(t("pages.management.detectors.alert-delete-error"));
      } else {
        await props.loadData();
      }
    }
    props.handleClosePopup();
  }

  async function handleAddInhibitors() {
    await props.loadData();
    props.handleClosePopup();
  }

  async function handleEditInhibitor() {
    await props.loadData();
    props.handleClosePopup();
  }

  async function handleRemoveInhibitor(inhibitorId: string) {
    if (userdataStore.user.role !== "SUPERADMIN") {
      alert(t("pages.management.inhibitors.alert-delete-error"));
    } else {
      if (!(await deleteInhibitor(inhibitorId))[0]) {
        alert(t("pages.management.inhibitors.alert-delete-error"));
      } else {
        await props.loadData();
      }
    }
    props.handleClosePopup();
  }

  async function handleRemoveInhibitorFromGroup(
    groupId: string,
    inhibitorId: string
  ) {
    if (userdataStore.user.group === groupId) {
      alert(t("pages.management.inhibitors.alert-delete-error"));
    } else {
      if (!(await removeInhibitorFromGroup(groupId, inhibitorId))[0]) {
        alert(t("pages.management.inhibitors.alert-delete-error"));
      } else {
        await props.loadData();
      }
    }
    props.handleClosePopup();
  }

  return (
    <div
      style={{
        width: "40%",
        height: "calc(100% - 42px)",
        minWidth: "400px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Tabs
        id={props.groupId}
        defaultActiveKey="users"
        justify
        style={{ width: "100%" }}
      >
        <Tab
          eventKey="users"
          title={t("pages.management.headers.users")}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ flex: 1, height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <div style={{ width: "100%" }}>
                <Form.Row>
                  <Form.Group as={Col} style={{ margin: 0 }}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        value={filterUsers}
                        onChange={(v) => {
                          setFilterUsers(v.target.value.toLowerCase());
                        }}
                        type="text"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ width: "200px" }}
                  type="submit"
                  onClick={() => {
                    props.setPopupContent({
                      showPopup: true,
                      fncClosePopup: props.handleClosePopup,
                      fncConfirm: handleCreateUser,
                      title: t("pages.management.users.popup.header"),
                      formId: "managementUserForm",
                      content: (
                        <ManagementUserForm
                          fncClosePopup={handleCreateUser}
                          groupId={props.groupId}
                          userGroupId={userdataStore.user.group}
                        />
                      ),
                    });
                  }}
                >
                  <span style={{ marginLeft: "10px" }}>
                    {t("pages.management.users.btn-add-user")}
                  </span>
                </Button>
              </div>
            </div>
            <div
              style={{
                minHeight: "400px",
                maxHeight: "80%",
                overflowY: "auto",
              }}
            >
              {props.users
                .filter(
                  (user) =>
                    user._id &&
                    ((user.email && user.email.toLowerCase().includes(filterUsers)) ||
                      (user.name && user.name.toLowerCase().includes(filterUsers)) ||
                      (user.identification &&
                        user.identification.toLowerCase().includes(filterUsers)) ||
                      (user.phoneNumber &&
                        user.phoneNumber.toLowerCase().includes(filterUsers)))
                )
                .map((user) => {
                  return (
                    <ListGroupItem
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={"user_" + user._id}
                    >
                      <div>
                        <span>{user.email}</span>{" "}
                        <span style={{ color: "gray" }}>
                          -{" "}
                          {userdataStore.user.group === "5f05c2b7861521159482dd4c"
                            ? rolesDictionary[user.role]
                            : user.role}
                        </span>
                      </div>
                      <div>
                        {userdataStore.user._id !== user._id ? (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{
                              marginLeft: "auto",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: handleEditUser,
                                title: t(
                                  "pages.management.users.edit-popup.header"
                                ),
                                formId: "managementEditUserForm",
                                content: (
                                  <ManagementEditUserForm
                                    fncClosePopup={handleEditUser}
                                    groupId={props.groupId}
                                    groupName={props.groupName}
                                    userGroupId={userdataStore.user.group}
                                    userToEdit={user}
                                    groupsList={props.groupsList}
                                  />
                                ),
                              });
                            }}
                          />
                        ) : null}
                        {userdataStore.user._id !== user._id &&
                        user.role !== "SUPERADMIN" ? (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: () => {
                                  handleDeleteUser(user._id);
                                },
                                title: t(
                                  "pages.management.users.delete-confirmation-header"
                                ),
                                content: (
                                  <div>
                                    {t(
                                      "pages.management.users.delete-confirmation-content"
                                    )}{" "}
                                    <b>{user.email}</b>
                                  </div>
                                ),
                              });
                            }}
                          />
                        ) : null}
                      </div>
                    </ListGroupItem>
                  );
                })}
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="detectors"
          title={t("pages.management.headers.detectors")}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ flex: 1, height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <div style={{ width: "100%" }}>
                <Form.Row>
                  <Form.Group as={Col} style={{ margin: 0 }}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        value={filterDetectors}
                        onChange={(v) => {
                          setFilterDetectors(v.target.value.toLowerCase());
                        }}
                        type="text"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {props.groupId === userdataStore.user.group &&
                userdataStore.user.group === "5f05780ab902bc3a58e303bb" ? (
                  <Button
                    style={{ width: "200px" }}
                    type="submit"
                    onClick={() => {
                      props.setPopupContent({
                        showPopup: true,
                        fncClosePopup: props.handleClosePopup,
                        fncConfirm: handleAddDetectors,
                        title: "Create detector",
                        formId: "managementCreateDetectorForm",
                        content: (
                          <ManagementCreateDetectorForm
                            fncClosePopup={handleAddDetectors}
                          />
                        ),
                      });
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>Create detector</span>
                  </Button>
                ) : null}
                {props.groupId !== userdataStore.user.group &&
                ((userdataStore.user.role === "ADMIN" &&
                  userdataStore.user.group === "5f05c2b7861521159482dd4c") ||
                  userdataStore.user.group === "5f05780ab902bc3a58e303bb") ? (
                  <Button
                    style={{ width: "200px" }}
                    type="submit"
                    onClick={() => {
                      props.setPopupContent({
                        showPopup: true,
                        fncClosePopup: props.handleClosePopup,
                        fncConfirm: handleAddDetectors,
                        title: t("pages.management.detectors.popup.header"),
                        formId: "managementDetectorForm",
                        content: (
                          <ManagementGroupDetectorForm
                            fncClosePopup={handleAddDetectors}
                            groupId={props.groupId}
                            detectors={props.detectors}
                            parentDetectors={props.parentDetectors}
                          />
                        ),
                      });
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      {t("pages.management.detectors.btn-add-detector")}
                    </span>
                  </Button>
                ) : null}
              </div>
            </div>
            <div
              style={{
                minHeight: "400px",
                maxHeight: "80%",
                overflowY: "auto",
              }}
            >
              {props.detectors
                .filter(
                  (detector) =>
                    detector._id &&
                    ((detector.name &&
                      detector.name.toLowerCase().includes(filterDetectors)) ||
                      (detector.identification &&
                        detector.identification.toLowerCase().includes(filterDetectors)))
                )
                .map((detector) => {
                  return (
                    <ListGroupItem
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={"detector_" + detector._id}
                    >
                      <div style={{ marginRight: "15px" }}>
                        <FontAwesomeIcon
                          icon={faCircle}
                          color={detector.isActive ? "green" : "red"}
                        />
                      </div>
                      <Col style={{ margin: 0, padding: 0 }}>
                        <div>
                          <span>{detector.name}</span>{" "}
                          <span style={{ color: "gray" }}>
                            {"SUPERADMIN" === userdataStore.user.role &&
                              ` - ${detector._id}`}
                          </span>
                        </div>
                        {detector.identification ? (
                          <div>
                            <span>{detector.identification}</span>
                          </div>
                        ) : null}
                      </Col>
                      <div>
                        {userdataStore.user.group === props.groupId &&
                        ((userdataStore.user.role === "ADMIN" &&
                          userdataStore.user.group ===
                            "5f05c2b7861521159482dd4c") ||
                          userdataStore.user.group ===
                            "5f05780ab902bc3a58e303bb") ? (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{
                              marginLeft: "auto",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: handleEditDetector,
                                title: t(
                                  "pages.management.detectors.edit-popup.header"
                                ),
                                formId: "managementEditDetectorForm",
                                content: (
                                  <ManagementEditDetectorForm
                                    fncClosePopup={handleEditDetector}
                                    userGroupId={userdataStore.user.group}
                                    groupName={props.groupName}
                                    detectorToEdit={detector}
                                    loadData={props.loadData}
                                    groupsAssigned={props.groupsList.filter(
                                      (group) =>
                                        group.detectors.includes(detector._id)
                                    )}
                                    groupsNotAssigned={props.groupsList.filter(
                                      (group) =>
                                        !group.detectors.includes(detector._id)
                                    )}
                                  />
                                ),
                              });
                            }}
                          />
                        ) : null}
                        {userdataStore.user.role === "SUPERADMIN" &&
                        props.groupId === userdataStore.user.group &&
                        userdataStore.user.group ===
                          "5f05780ab902bc3a58e303bb" ? (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: () => {
                                  handleRemoveDetector(detector._id);
                                },
                                title: t(
                                  "pages.management.detectors.delete-confirmation-header"
                                ),
                                content: (
                                  <div>
                                    If accepted, the detection system{" "}
                                    <b>{detector.name}</b> will be deleted from
                                    the system
                                  </div>
                                ),
                              });
                            }}
                          />
                        ) : null}
                        {userdataStore.user.group !== props.groupId ? (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: () => {
                                  handleRemoveDetectorFromGroup(
                                    props.groupId,
                                    detector._id
                                  );
                                },
                                title: t(
                                  "pages.management.detectors.delete-confirmation-header"
                                ),
                                content: (
                                  <div>
                                    {t(
                                      "pages.management.detectors.delete-confirmation-content"
                                    )}{" "}
                                    <b>{detector.name}</b>{" "}
                                    {t(
                                      "pages.management.detectors.delete-confirmation-content2"
                                    )}{" "}
                                    <b>{props.groupName}</b>
                                  </div>
                                ),
                              });
                            }}
                          />
                        ) : null}
                      </div>
                    </ListGroupItem>
                  );
                })}
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="inhibitors"
          title={t("pages.management.headers.inhibitors")}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ flex: 1, height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <div style={{ width: "100%" }}>
                <Form.Row>
                  <Form.Group as={Col} style={{ margin: 0 }}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        value={filterInhibitors}
                        onChange={(v) => {
                          setFilterInhibitors(v.target.value.toLowerCase());
                        }}
                        type="text"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {props.groupId === userdataStore.user.group &&
                userdataStore.user.group === "5f05780ab902bc3a58e303bb" ? (
                  <Button
                    style={{ width: "200px" }}
                    type="submit"
                    onClick={() => {
                      props.setPopupContent({
                        showPopup: true,
                        fncClosePopup: props.handleClosePopup,
                        fncConfirm: handleAddInhibitors,
                        title: "Create inhibitor",
                        formId: "managementCreateInhibitorForm",
                        content: (
                          <ManagementCreateInhibitorForm
                            fncClosePopup={handleAddInhibitors}
                          />
                        ),
                      });
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>Create inhibitor</span>
                  </Button>
                ) : null}
                {props.groupId !== userdataStore.user.group &&
                ((userdataStore.user.role === "ADMIN" &&
                  userdataStore.user.group === "5f05c2b7861521159482dd4c") ||
                  userdataStore.user.group === "5f05780ab902bc3a58e303bb") ? (
                  <Button
                    style={{ width: "200px" }}
                    type="submit"
                    onClick={() => {
                      props.setPopupContent({
                        showPopup: true,
                        fncClosePopup: props.handleClosePopup,
                        fncConfirm: handleAddInhibitors,
                        title: t("pages.management.inhibitors.popup.header"),
                        formId: "managementInhibitorForm",
                        content: (
                          <ManagementGroupInhibitorForm
                            fncClosePopup={handleAddInhibitors}
                            groupId={props.groupId}
                            inhibitors={props.inhibitors}
                            parentInhibitors={props.parentInhibitors}
                          />
                        ),
                      });
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      {t("pages.management.inhibitors.btn-add-inhibitor")}
                    </span>
                  </Button>
                ) : null}
              </div>
            </div>
            <div
              style={{
                minHeight: "400px",
                maxHeight: "80%",
                overflowY: "auto",
              }}
            >
              {props.inhibitors
                .filter(
                  (inhibitor) =>
                    inhibitor._id &&
                    ((inhibitor.name &&
                      inhibitor.name.toLowerCase().includes(filterInhibitors)) ||
                      (inhibitor.identification &&
                        inhibitor.identification.toLowerCase().includes(filterInhibitors)))
                )
                .map((inhibitor) => {
                  return (
                    <ListGroupItem
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={"inhibitor_" + inhibitor._id}
                    >
                      <div style={{ marginRight: "15px" }}>
                        <FontAwesomeIcon
                          icon={faCircle}
                          color={inhibitor.isActive ? "green" : "red"}
                        />
                      </div>
                      <Col style={{ margin: 0, padding: 0 }}>
                        <div>
                          <span>{inhibitor.name}</span>{" "}
                          <span style={{ color: "gray" }}>
                            {"SUPERADMIN" === userdataStore.user.role &&
                              ` - ${inhibitor._id}`}
                          </span>
                        </div>
                        {inhibitor.identification ? (
                          <div>
                            <span>{inhibitor.identification}</span>
                          </div>
                        ) : null}
                      </Col>
                      <div>
                        {userdataStore.user.group === props.groupId &&
                        ((userdataStore.user.role === "ADMIN" &&
                          userdataStore.user.group ===
                            "5f05c2b7861521159482dd4c") ||
                          userdataStore.user.group ===
                            "5f05780ab902bc3a58e303bb") ? (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{
                              marginLeft: "auto",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: handleEditInhibitor,
                                title: t(
                                  "pages.management.inhibitors.edit-popup.header"
                                ),
                                formId: "managementEditInhibitorForm",
                                content: (
                                  <ManagementEditInhibitorForm
                                    fncClosePopup={handleEditInhibitor}
                                    userGroupId={userdataStore.user.group}
                                    groupName={props.groupName}
                                    inhibitorToEdit={inhibitor}
                                    loadData={props.loadData}
                                    groupsAssigned={props.groupsList.filter(
                                      (group) =>
                                        group.inhibitors.includes(inhibitor._id)
                                    )}
                                    groupsNotAssigned={props.groupsList.filter(
                                      (group) =>
                                        !group.inhibitors.includes(
                                          inhibitor._id
                                        )
                                    )}
                                  />
                                ),
                              });
                            }}
                          />
                        ) : null}
                        {userdataStore.user.role === "SUPERADMIN" &&
                        props.groupId === userdataStore.user.group &&
                        userdataStore.user.group ===
                          "5f05780ab902bc3a58e303bb" ? (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: () => {
                                  handleRemoveInhibitor(inhibitor._id);
                                },
                                title: t(
                                  "pages.management.inhibitors.delete-confirmation-header"
                                ),
                                content: (
                                  <div>
                                    If accepted, the netralization system{" "}
                                    <b>{inhibitor.name}</b> will be deleted from
                                    the system
                                  </div>
                                ),
                              });
                            }}
                          />
                        ) : null}
                        {userdataStore.user.group !== props.groupId ? (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={() => {
                              props.setPopupContent({
                                showPopup: true,
                                fncClosePopup: props.handleClosePopup,
                                fncConfirm: () => {
                                  handleRemoveInhibitorFromGroup(
                                    props.groupId,
                                    inhibitor._id
                                  );
                                },
                                title: t(
                                  "pages.management.inhibitors.delete-confirmation-header"
                                ),
                                content: (
                                  <div>
                                    {t(
                                      "pages.management.inhibitors.delete-confirmation-content"
                                    )}{" "}
                                    <b>{inhibitor.name}</b>{" "}
                                    {t(
                                      "pages.management.inhibitors.delete-confirmation-content2"
                                    )}{" "}
                                    <b>{props.groupName}</b>
                                  </div>
                                ),
                              });
                            }}
                          />
                        ) : null}
                      </div>
                    </ListGroupItem>
                  );
                })}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ManagementPage;
