import React, { useState } from "react";
import { Form, Row } from "react-bootstrap";
import { createInhibitor } from "../../../api";
import { useTranslation } from "react-i18next";

export function ManagementCreateInhibitorForm(props: {
  fncClosePopup: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [inhibitorName, setInhibitorName] = useState<string>();
  const [inhibitorPassword, setInhibitorPassword] = useState<string>();
  const [inhibitorMode, setInhibitorMode] = useState<string>();
  const [inhibitorType, setInhibitorType] = useState<"STATIONARY" | "MOBILE">(
    "STATIONARY"
  );

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (!inhibitorName || !inhibitorPassword || !inhibitorMode) {
      alert(t("pages.management.inhibitors.popup.alert-invalid-create"));
    } else {
      if (
        !(
          await createInhibitor({
            name: inhibitorName,
            password: inhibitorPassword,
            inhibitionMode: inhibitorMode,
            location: { lat: 0, lon: 0, height: 400 },
            isMobile: inhibitorType === "MOBILE",
          })
        )[0]
      ) {
        alert(t("pages.management.inhibitors.popup.alert-create-error"));
      } else {
        props.fncClosePopup();
      }
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <Form
        style={{ width: "100%" }}
        id="managementCreateInhibitorForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formInhibitorName">
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.inhibitors.popup.label-name")}
          </Form.Label>
          <Form.Control
            value={inhibitorName}
            onChange={(v) => setInhibitorName(v.target.value)}
            type="text"
            placeholder={t("pages.management.inhibitors.popup.placeholder-name")}
          />
        </Form.Group>
        <Form.Group controlId="formInhibitorPassword">
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.inhibitors.popup.label-password")}
          </Form.Label>
          <Form.Control
            value={inhibitorPassword}
            onChange={(v) => setInhibitorPassword(v.target.value)}
            type="text"
            placeholder={t("pages.management.inhibitors.popup.placeholder-password")}
          />
        </Form.Group>
        <Form.Group controlId="formInhibitorMode">
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.inhibitors.popup.label-mode")}
          </Form.Label>
          <Form.Control
            value={inhibitorMode}
            onChange={(v) => setInhibitorMode(v.target.value)}
            as="select"
          >
            <option value={""}>-----------</option>
            <option value={"omni"}>
              {t("pages.management.inhibitors.omni")}
            </option>
            <option value={"sect"}>
              {t("pages.management.inhibitors.sectorial")}
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formInhibitorType" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.popup.label-type")}
          </Form.Label>
          <Form.Control
            value={inhibitorType}
            onChange={(v) =>
              setInhibitorType(v.target.value as "STATIONARY" | "MOBILE")
            }
            as="select"
          >
            <option value={"STATIONARY"}>
              {t("pages.management.inhibitors.popup.fixed-inhibitor")}
            </option>
            <option value={"MOBILE"}>
              {t("pages.management.inhibitors.popup.mobile-inhibitor")}
            </option>
          </Form.Control>
        </Form.Group>
      </Form>
    </Row>
  );
}
