import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { editUser } from "../../../api";
import { IGroupUser } from "../../../interfaces/group.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

export function ManagementEditUserForm(props: {
  userGroupId: string;
  groupId: string;
  groupName: string;
  userToEdit: IGroupUser;
  fncClosePopup: Function;
  groupsList: Array<{ id: string; name: string }>;
}) {
  const [t, i18n] = useTranslation("common");

  const [userName, setUserName] = useState<string>(props.userToEdit.name);
  const [userEmail, setUserEmail] = useState<string>(props.userToEdit.email);
  const [userRole, setUserRole] = useState<string>(props.userToEdit.role);
  const [userGroup, setUserGroup] = useState<string>(props.userToEdit.group);
  const [userPassword, setUserPassword] = useState<string>("");
  const [userIdentification, setUserIdentification] = useState<string>(
    props.userToEdit.identification
  );
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>(
    props.userToEdit.phoneNumber
  );

  useEffect(() => {
    cleanData();
  }, []);

  function cleanData() {
    setUserName(props.userToEdit.name);
    setUserEmail(props.userToEdit.email);
    setUserRole(props.userToEdit.role);
    setUserGroup(props.userToEdit.group);
    setUserPassword("");
    setUserIdentification(props.userToEdit.identification);
    setUserPhoneNumber(props.userToEdit.phoneNumber);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (
      !userName ||
      !userEmail ||
      !userRole ||
      (props.userGroupId === "test" &&
        (!userIdentification ||
          !validateDNI() ||
          !userPhoneNumber ||
          userPhoneNumber.length !== 9))
    ) {
      alert(t("pages.management.users.edit-popup.alert-invalid-create"));
    } else {
      if (
        !(
          await editUser(props.userToEdit._id, {
            name: userName,
            group: userGroup,
            email: userEmail,
            role: userRole,
            password: userPassword,
            phoneNumber: userPhoneNumber,
            identification: userIdentification,
          })
        )[0]
      ) {
        alert(t("pages.management.users.edit-popup.alert-create-error"));
      } else {
        props.fncClosePopup();
      }
    }
  }

  function generateRandomPassword() {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!?.$";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 8) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setUserPassword(result);
  }

  function validateDNI() {
    var numero: any;
    var letr: string;
    var letra: string;
    var expresion_regular_dni: any;

    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(userIdentification) == true) {
      numero = userIdentification.substr(0, userIdentification.length - 1);
      letr = userIdentification.substr(userIdentification.length - 1, 1);
      numero = numero % 23;
      letra = "TRWAGMYFPDXBNJZSQVHLCKET";
      letra = letra.substring(numero, numero + 1);
      if (letra != letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <Form
        style={{ width: "100%" }}
        id="managementEditUserForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formUserName" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.edit-popup.label-username")}
          </Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={userName}
            onChange={(v) => setUserName(v.target.value)}
            type="text"
          />
        </Form.Group>
        <Form.Group controlId="formUserEmail" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.edit-popup.label-email")}
          </Form.Label>
          <Form.Control
            value={userEmail}
            onChange={(v) => setUserEmail(v.target.value)}
            type="text"
            placeholder={t(
              "pages.management.users.edit-popup.placeholder-email"
            )}
          />
        </Form.Group>
        <Form.Group controlId="formUserRole" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.edit-popup.label-role")}
          </Form.Label>
          <Form.Control
            value={userRole}
            onChange={(v) => setUserRole(v.target.value)}
            as="select"
          >
            <option value={""}>-----------</option>
            <option value={"ADMIN"}>ADMINISTRADOR DEPARTAMENTAL</option>
            <option value={"SUPERUSER"}>ADMINISTRADOR LOCAL</option>
            <option value={"ADVANCEDUSER"}>USUARIO AVANZADO</option>
            <option value={"USER"}>USUARIO</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formUserGroup" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.edit-popup.label-group")}
          </Form.Label>
          <Form.Control
            value={userGroup}
            onChange={(v) => setUserGroup(v.target.value)}
            as="select"
          >
            {props.groupsList.map((group) => (
              <option value={group.id}>{group.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formUserPassword" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.edit-popup.label-password")}
          </Form.Label>
          <Row
            style={{
              margin: 0,
              padding: 0,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Row style={{ width: "60%" }}>
              <Form.Group as={Col} style={{ margin: 0 }}>
                <InputGroup>
                  <Form.Control
                    value={userPassword}
                    disabled
                    type="text"
                    placeholder={t(
                      "pages.management.users.edit-popup.placeholder-password"
                    )}
                  />
                  <InputGroup.Text
                    style={{ marginLeft: -2, cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(userPassword || "");
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Button
              onClick={() => {
                generateRandomPassword();
              }}
            >
              {t("pages.management.users.edit-popup.btn-generate")}
            </Button>
          </Row>
          <p style={{ marginTop: "1rem" }}>
            {t("pages.management.users.edit-popup.info")}
          </p>
        </Form.Group>
        <Form.Group
          controlId="formUserIdentification"
          style={{ width: "100%" }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>DNI</Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={userIdentification}
            onChange={(v) => setUserIdentification(v.target.value)}
            type="text"
            placeholder="DNI"
          />
        </Form.Group>
        <Form.Group controlId="formUserPhoneNumber" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            Número de teléfono
          </Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={userPhoneNumber}
            onChange={(v) => setUserPhoneNumber(v.target.value)}
            type="text"
            placeholder="600000000"
          />
        </Form.Group>
      </Form>
    </Row>
  );
}
