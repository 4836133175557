import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { createUser } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

export function ManagementUserForm(props: {
  userGroupId: string;
  groupId: string;
  fncClosePopup: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [userName, setUserName] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();
  const [userRole, setUserRole] = useState<string>();
  const [userPassword, setUserPassword] = useState<string>();
  const [userIdentification, setUserIdentification] = useState<string>("");
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");

  useEffect(() => {
    cleanData();
  }, []);

  function cleanData() {
    setUserName("");
    setUserEmail("");
    setUserRole("");
    setUserPassword("");
    setUserIdentification("");
    setUserPhoneNumber("");
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (!userName || !userEmail || !userRole || !userPassword) {
      alert(t("pages.management.users.popup.alert-invalid-create"));
    } else {
      if (
        !(
          await createUser({
            name: userName,
            group: props.groupId,
            email: userEmail,
            role: userRole,
            password: userPassword,
            phoneNumber: userPhoneNumber,
            identification: userIdentification,
          })
        )[0]
      ) {
        alert(t("pages.management.users.popup.alert-create-error"));
      } else {
        cleanData();
        props.fncClosePopup();
      }
    }
  }

  function generateRandomPassword() {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!?.$";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 8) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setUserPassword(result);
  }

  function validateDNI() {
    var numero: any;
    var letr: string;
    var letra: string;
    var expresion_regular_dni: any;

    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(userIdentification) == true) {
      numero = userIdentification.substr(0, userIdentification.length - 1);
      letr = userIdentification.substr(userIdentification.length - 1, 1);
      numero = numero % 23;
      letra = "TRWAGMYFPDXBNJZSQVHLCKET";
      letra = letra.substring(numero, numero + 1);
      if (letra != letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <Form
        style={{ width: "100%" }}
        id="managementUserForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formUserName" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.popup.label-username")}
          </Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={userName}
            onChange={(v) => setUserName(v.target.value)}
            type="text"
            placeholder={t("pages.management.users.popup.placeholder-username")}
          />
        </Form.Group>
        <Form.Group controlId="formUserEmail" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.popup.label-email")}
          </Form.Label>
          <Form.Control
            value={userEmail}
            onChange={(v) => setUserEmail(v.target.value)}
            type="text"
            placeholder={t("pages.management.users.popup.placeholder-email")}
          />
        </Form.Group>
        <Form.Group controlId="formUserRole" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.popup.label-role")}
          </Form.Label>
          <Form.Control
            value={userRole}
            onChange={(v) => setUserRole(v.target.value)}
            as="select"
          >
            <option value={""}>-----------</option>
            <option value={"ADMIN"}>ADMINISTRADOR DEPARTAMENTAL</option>
            <option value={"SUPERUSER"}>ADMINISTRADOR LOCAL</option>
            <option value={"ADVANCEDUSER"}>USUARIO AVANZADO</option>
            <option value={"USER"}>USUARIO</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formUserPassword" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.users.popup.label-password")}
          </Form.Label>
          <Row
            style={{
              margin: 0,
              padding: 0,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Row style={{ width: "60%" }}>
              <Form.Group as={Col} style={{ margin: 0 }}>
                <InputGroup>
                  <Form.Control
                    value={userPassword}
                    disabled
                    type="text"
                    placeholder={t(
                      "pages.management.users.popup.placeholder-password"
                    )}
                  />
                  <InputGroup.Text
                    style={{ marginLeft: -2, cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(userPassword || "");
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Button
              onClick={() => {
                generateRandomPassword();
              }}
            >
              {t("pages.management.users.popup.btn-generate")}
            </Button>
          </Row>
          <p style={{ marginTop: "1rem" }}>
            {t("pages.management.users.popup.info")}
          </p>
        </Form.Group>
        <Form.Group
          controlId="formUserIdentification"
          style={{ width: "100%" }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>{t("pages.management.users.popup.identifier")}</Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={userIdentification}
            onChange={(v) => setUserIdentification(v.target.value)}
            type="text"
            placeholder={t("pages.management.users.popup.identifier-placeholder")}
          />
        </Form.Group>
        <Form.Group controlId="formUserPhoneNumber" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
          {t("pages.management.users.popup.phone-number")}
          </Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={userPhoneNumber}
            onChange={(v) => setUserPhoneNumber(v.target.value)}
            type="text"
            placeholder={t("pages.management.users.popup.phone-number-placeholder")}
          />
        </Form.Group>
      </Form>
    </Row>
  );
}
