import React, { useState } from "react";
import { Form, Row } from "react-bootstrap";
import { createDetector } from "../../../api";
import { useTranslation } from "react-i18next";

export function ManagementCreateDetectorForm(props: {
  fncClosePopup: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [detectorName, setDetectorName] = useState<string>();
  const [detectorPassword, setDetectorPassword] = useState<string>();
  const [detectorType, setDetectorType] = useState<"STATIONARY" | "MOBILE">(
    "STATIONARY"
  );

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (!detectorName || !detectorPassword) {
      alert(t("pages.management.detectors.popup.alert-invalid-create"));
    } else {
      if (
        !(
          await createDetector({
            name: detectorName,
            password: detectorPassword,
            location: { lat: 0, lon: 0, height: 400 },
            isMobile: detectorType === "MOBILE",
          })
        )[0]
      ) {
        alert(t("pages.management.detectors.popup.alert-create-error"));
      } else {
        props.fncClosePopup();
      }
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <Form
        style={{ width: "100%" }}
        id="managementCreateDetectorForm"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formDetectorName">
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.popup.label-name")}
          </Form.Label>
          <Form.Control
            value={detectorName}
            onChange={(v) => setDetectorName(v.target.value)}
            type="text"
            placeholder={t("pages.management.detectors.popup.placeholder-name")}
          />
        </Form.Group>
        <Form.Group controlId="formDetectorPassword">
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.popup.label-password")}
          </Form.Label>
          <Form.Control
            value={detectorPassword}
            onChange={(v) => setDetectorPassword(v.target.value)}
            type="text"
            placeholder={t(
              "pages.management.detectors.popup.placeholder-password"
            )}
          />
        </Form.Group>
        <Form.Group controlId="formDetectorType" style={{ width: "100%" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.popup.label-type")}
          </Form.Label>
          <Form.Control
            value={detectorType}
            onChange={(v) =>
              setDetectorType(v.target.value as "STATIONARY" | "MOBILE")
            }
            as="select"
          >
            <option value={"STATIONARY"}>
              {t("pages.management.detectors.popup.fixed-detector")}
            </option>
            <option value={"MOBILE"}>
              {t("pages.management.detectors.popup.mobile-detector")}
            </option>
          </Form.Control>
        </Form.Group>
      </Form>
    </Row>
  );
}
