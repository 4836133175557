import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, ListGroupItem, Row } from "react-bootstrap";
import {
  addDetectorToGroup,
  editDetector,
  removeDetectorFromGroup,
} from "../../../api";
import { IGroupDetector } from "../../../interfaces/group.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ManagementPopup } from "./managementPopup";

export function ManagementEditDetectorForm(props: {
  userGroupId: string;
  groupName: string;
  detectorToEdit: IGroupDetector;
  fncClosePopup: Function;
  groupsAssigned: Array<{ id: string; name: string }>;
  groupsNotAssigned: Array<{ id: string; name: string }>;
  loadData: Function;
}) {
  const [t, i18n] = useTranslation("common");

  const [detectorIdentification, setDetectorIdentification] = useState<string>(
    props.detectorToEdit.identification
  );
  const [popupContent, setPopupContent] = useState<{
    showPopup: boolean;
    fncClosePopup?: Function;
    fncConfirm?: Function;
    title?: string;
    formId?: string;
    content?: React.ReactNode;
  }>({
    showPopup: false,
  });
  const [groupsAssigned, setGroupsAssigned] = useState<
    Array<{ id: string; name: string }>
  >(props.groupsAssigned);
  const [groupsNotAssigned, setGroupsNotAssigned] = useState<
    Array<{ id: string; name: string }>
  >(props.groupsNotAssigned);

  useEffect(() => {
    cleanData();
  }, []);

  function cleanData() {
    setDetectorIdentification(props.detectorToEdit.identification);
    setGroupsAssigned(props.groupsAssigned);
    setGroupsNotAssigned(props.groupsNotAssigned);
  }

  function handleClosePopup() {
    setPopupContent({ showPopup: false });
  }

  async function handleRemoveDetectorFromGroup(
    groupId: string,
    groupName: string,
    detectorId: string
  ) {
    if (props.userGroupId === groupId) {
      alert(t("pages.management.detectors.alert-delete-error"));
    } else {
      if (!(await removeDetectorFromGroup(groupId, detectorId))[0]) {
        alert(t("pages.management.detectors.alert-delete-error"));
      } else {
        await props.loadData();

        let groupsAssignedAux: Array<{ name: string; id: string }> =
          JSON.parse(JSON.stringify(groupsAssigned));
        groupsAssignedAux.splice(
          groupsAssignedAux.findIndex(
            (group: { id: string }) => group.id === groupId
          ),
          1
        );
        groupsAssignedAux.sort((a, b) => (a.name > b.name ? 1 : -1));
        setGroupsAssigned(groupsAssignedAux);

        let groupsNotAssignedAux: Array<{ name: string; id: string }> = JSON.parse(
          JSON.stringify(groupsNotAssigned)
        );
        groupsNotAssignedAux.push({ id: groupId, name: groupName });
        groupsNotAssignedAux.sort((a, b) => (a.name > b.name ? 1 : -1));
        setGroupsNotAssigned(groupsNotAssignedAux);
      }
    }
    handleClosePopup();
  }

  async function handleAddDetectorToGroup(
    groupId: string,
    groupName: string,
    detectorId: string
  ) {
    if (props.userGroupId === groupId) {
      alert(t("pages.management.detectors.alert-add-error"));
    } else {
      if (!(await addDetectorToGroup(groupId, detectorId))[0]) {
        alert(t("pages.management.detectors.alert-add-error"));
      } else {
        await props.loadData();

        let groupsNotAssignedAux: Array<{ name: string; id: string }> =
          JSON.parse(JSON.stringify(groupsNotAssigned));
        groupsNotAssignedAux.splice(
          groupsNotAssignedAux.findIndex(
            (group: { id: string }) => group.id === groupId
          ),
          1
        );
        groupsNotAssignedAux.sort((a, b) => (a.name > b.name ? 1 : -1));
        setGroupsNotAssigned(groupsNotAssignedAux);

        let groupsAssignedAux: Array<{ name: string; id: string }> = JSON.parse(
          JSON.stringify(groupsAssigned)
        );
        groupsAssignedAux.push({ id: groupId, name: groupName });
        groupsAssignedAux.sort((a, b) => (a.name > b.name ? 1 : -1));
        setGroupsAssigned(groupsAssignedAux);
      }
    }
    handleClosePopup();
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (
      !(
        await editDetector(props.detectorToEdit._id, {
          identification: detectorIdentification,
        })
      )[0]
    ) {
      alert(t("pages.management.detectors.edit-popup.alert-create-error"));
    } else {
      props.fncClosePopup();
    }
  }

  return (
    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
      <ManagementPopup
        show={popupContent["showPopup"]}
        handleCancel={popupContent["fncClosePopup"]!}
        handleConfirm={popupContent["fncConfirm"]!}
        title={popupContent["title"]!}
        formId={popupContent["formId"]}
      >
        {popupContent["content"]}
      </ManagementPopup>
      <Form
        style={{ width: "100%" }}
        id="managementEditDetectorForm"
        onSubmit={handleSubmit}
      >
        <Form.Group
          controlId="formDetectorIdentification"
          style={{ width: "100%" }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.edit-popup.label-identifier")}
          </Form.Label>
          <Form.Control
            style={{ width: "100%" }}
            value={detectorIdentification}
            onChange={(v) => setDetectorIdentification(v.target.value)}
            type="text"
          />
        </Form.Group>
        <Form.Group
          controlId="formDetectorAssignedGroups"
          style={{ width: "100%" }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            {t("pages.management.detectors.edit-popup.label-assigned-groups")}
          </Form.Label>
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
            {groupsAssigned.map((group) => (
              <ListGroupItem
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                key={"detector_group_" + group.id}
              >
                <span>{group.name}</span>
                {group.id !== props.userGroupId ? (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => {
                      setPopupContent({
                        showPopup: true,
                        fncClosePopup: handleClosePopup,
                        fncConfirm: () => {
                          handleRemoveDetectorFromGroup(
                            group.id,
                            group.name,
                            props.detectorToEdit._id
                          );
                          handleClosePopup();
                        },
                        title: t(
                          "pages.management.detectors.delete-confirmation-header"
                        ),
                        content: (
                          <div>
                            {t(
                              "pages.management.detectors.delete-confirmation-content"
                            )}{" "}
                            <b>{props.detectorToEdit.name}</b>{" "}
                            {t(
                              "pages.management.detectors.delete-confirmation-content2"
                            )}{" "}
                            <b>{group.name}</b>
                          </div>
                        ),
                      });
                    }}
                  />
                ) : null}
              </ListGroupItem>
            ))}
          </div>
        </Form.Group>
        <Form.Group
          controlId="formDetectorNotAssignedGroups"
          style={{ width: "100%" }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            {t(
              "pages.management.detectors.edit-popup.label-not-assigned-groups"
            )}
          </Form.Label>
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
            {groupsNotAssigned.map((group) => (
              <ListGroupItem
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                key={"detector_group_" + group.id}
              >
                <span>{group.name}</span>
                {group.id !== props.userGroupId ? (
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => {
                      setPopupContent({
                        showPopup: true,
                        fncClosePopup: handleClosePopup,
                        fncConfirm: () => {
                          handleAddDetectorToGroup(
                            group.id,
                            group.name,
                            props.detectorToEdit._id
                          );
                        },
                        title: t(
                          "pages.management.detectors.add-confirmation-header"
                        ),
                        content: (
                          <div>
                            {t(
                              "pages.management.detectors.add-confirmation-content"
                            )}{" "}
                            <b>{props.detectorToEdit.name}</b>{" "}
                            {t(
                              "pages.management.detectors.add-confirmation-content2"
                            )}{" "}
                            <b>{group.name}</b>
                          </div>
                        ),
                      });
                    }}
                  />
                ) : null}
              </ListGroupItem>
            ))}
          </div>
        </Form.Group>
      </Form>
    </Row>
  );
}
